import React from 'react';
import PropTypes from 'prop-types';

import DocumentSummary from 'components/shared/DocumentSummary';
import Button from 'components/shared/Button';
import LinkButton from 'components/shared/LinkButton';

import {
  BoldSubTitle,
  ButtonWrapper,
  CenteredTitle,
  ConfirmationPageButtonsWrapper,
  LinkTitle,
  Title,
  Wrapper,
} from './style';

export const CancelPageConfirmation = ({
  currentStep,
  didCancel,
  onCancelReservation,
  onHomeRedirect,
  onResetCancelState,
  permitId,
  userReservation,
}) => {
  const { subTitle, title } = currentStep;

  let content;
  if (didCancel) {
    content = (
      <>
        <CenteredTitle>Your reservation was successfully cancelled.</CenteredTitle>
        <br />
        <LinkButton isBiggerText onClick={() => onHomeRedirect('Make Another Reservation Button Clicked')}>
          <LinkTitle>Make a reservation?</LinkTitle>
        </LinkButton>
        <br />
        <LinkButton isBiggerText onClick={() => onResetCancelState('Cancel another reservation clicked')}>
          <LinkTitle>Cancel another reservation?</LinkTitle>
        </LinkButton>
      </>
    );
  } else {
    content = (
      <Wrapper>
        <Title>{title}</Title>
        <BoldSubTitle>{`${subTitle}${permitId}`}</BoldSubTitle>
        <DocumentSummary reservation={userReservation} />
        <form>
          <ConfirmationPageButtonsWrapper>
            <ButtonWrapper>
              <Button onClick={onCancelReservation} variant="red">
                Cancel
              </Button>
            </ButtonWrapper>
            <Button onClick={() => onResetCancelState('I do not want to cancel button clicked')}>Do not cancel</Button>
          </ConfirmationPageButtonsWrapper>
        </form>
      </Wrapper>
    );
  }

  return content;
};

CancelPageConfirmation.propTypes = {
  currentStep: PropTypes.objectOf(PropTypes.any).isRequired,
  didCancel: PropTypes.bool.isRequired,
  onCancelReservation: PropTypes.func.isRequired,
  onHomeRedirect: PropTypes.func.isRequired,
  userReservation: PropTypes.objectOf(PropTypes.any).isRequired,
  values: PropTypes.objectOf(PropTypes.any),
};

CancelPageConfirmation.defaultProps = {
  values: {},
};

export default CancelPageConfirmation;
