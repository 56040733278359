/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import LinkButton from 'components/shared/LinkButton';
import Button from 'components/shared/Button';

import TableCell from './TableCell';
import {
  ArrowIcon,
  CalendarWrapper,
  CellTitle,
  FooterWrapper,
  Header,
  HeaderCell,
  LeftButtonWrapper,
  MonthYearTextWrapper,
  MoreInfoText,
  RightButtonWrapper,
  SubTitle,
  Table,
  TableRow,
  Title,
  TitleWrapper,
} from './style';

const Calendar = ({
  availability,
  calendarTitle,
  error,
  furthestStartDate,
  headerItems,
  isDateAfterMax,
  isDateBeforeToday,
  isLoading,
  isSubmitEnabled,
  moreInfoText,
  onDateChange,
  onResetButtonClick,
  onSubmit,
  onUserSelection,
  reservationTypeId,
  subTitle,
  title,
  userSelection,
}) => {
  let content;

  if (error)
    content = (
      <SubTitle>
        Looks like there was an error related to fetching more dates. Please refresh the page and try again.
      </SubTitle>
    );
  else {
    content = (
      <CalendarWrapper>
        <TitleWrapper>
          <Title>{title}</Title>
          <p>{subTitle}</p>
        </TitleWrapper>
        <MonthYearTextWrapper>{calendarTitle}</MonthYearTextWrapper>
        <Table aria-label="Availability by Sites and Dates">
          <Header>
            <LeftButtonWrapper
              aria-label="Go back one calendar day"
              isDisabled={isDateBeforeToday}
              onClick={() => onDateChange(-1)}
            >
              <ArrowIcon />
            </LeftButtonWrapper>
            <RightButtonWrapper
              aria-label="Go forward one calendar day"
              isDisabled={isDateAfterMax}
              onClick={() => onDateChange(1)}
            >
              <ArrowIcon isRight />
            </RightButtonWrapper>
            <TableRow isFirstChild>
              <HeaderCell>Sites</HeaderCell>
              {headerItems.length
                ? headerItems.map((item) => <HeaderCell key={item.day}>{item.date}</HeaderCell>)
                : ['--', '--', '--', '--', '--'].map((defaultState, index) => (
                    <HeaderCell key={index}>{defaultState}</HeaderCell>
                  ))}
            </TableRow>
          </Header>
          {availability.map((site) => (
            <TableRow key={site.id} isDisabled={false}>
              <CellTitle>
                {site.title}
                {site.areaTitle ? <p>{site.areaTitle}</p> : null}
              </CellTitle>
              <TableCell
                furthestStartDate={furthestStartDate}
                headerItems={headerItems}
                isLoading={isLoading}
                onUserSelection={onUserSelection}
                reservationTypeId={reservationTypeId}
                selection={userSelection}
                site={site}
              />
            </TableRow>
          ))}
        </Table>
        <FooterWrapper>
          <div>
            <Button type="submit" disabled={!isSubmitEnabled} onClick={onSubmit}>
              Submit
            </Button>
            {moreInfoText && <MoreInfoText>{moreInfoText}</MoreInfoText>}
          </div>
          <LinkButton onClick={onResetButtonClick} disabled={!userSelection}>
            Reset Dates
          </LinkButton>
        </FooterWrapper>
      </CalendarWrapper>
    );
  }

  return content;
};

export default Calendar;
