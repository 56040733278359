import { createAction, createAsyncAction, createAsyncTypes } from 'lib/reduxUtils';

export const RESET_RESERVATION = 'reservations/resetReservation';
export const resetReservation = createAction(RESET_RESERVATION);

export const FETCH_AVAILABILITY = createAsyncTypes('reservations/fetchAvailability');
export const fetchAvailability = createAsyncAction(FETCH_AVAILABILITY);

export const FETCH_RESERVATION_OPTIONS = createAsyncTypes('reservations/fetchReservationOptions');
export const fetchReservationOptions = createAsyncAction(FETCH_RESERVATION_OPTIONS);

export const SUBMIT_RESERVATION = createAsyncTypes('reservations/submitReservation');
export const submitReservation = createAsyncAction(SUBMIT_RESERVATION);

export const GENERATE_PERMIT = createAsyncTypes('reservations/generatePermit');
export const generatePermit = createAsyncAction(GENERATE_PERMIT);

export const CANCEL_RESERVATION = createAsyncTypes('reservations/cancelReservation');
export const cancelReservation = createAsyncAction(CANCEL_RESERVATION);

export const CHECK_RESERVATION_AVAILABILITY = createAsyncTypes('reservations/checkReservationAvailability');
export const checkReservationAvailability = createAsyncAction(CHECK_RESERVATION_AVAILABILITY);
