import styled from 'styled-components';
import { media } from 'styles';

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #000000;

  ${media.twoDimMin.tablet`
    font-size: 2.4rem;
  `}
`;

export const IntroStepWrapper = styled.div`
  width: 100%;
  padding: 20px;
`;
