import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/shared/Button';
import { InlineButtonWrapper, SubTitle, TextFieldInput, TextFieldWrapper, Title } from './style';

const TextField = ({ id, onChange, onSubmit, subTitle, title, type, value }) => {
  const field = useRef();

  useEffect(() => {
    field.current.focus();
  }, []);

  const validatePhone = (number) => {
    const re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    return re.test(String(number));
  };

  return (
    <>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <form>
        <TextFieldWrapper>
          <TextFieldInput
            id={id}
            name={id}
            onChange={(event) => onChange([id], event.target.value)}
            placeholder={type === 'tel' ? 'Ex: 123-123-1234' : 'Type your answer here...'}
            required
            type={type}
            value={value}
            validate={type === 'tel' ? validatePhone(value) : null}
            ref={field}
          />
          {!!value && (
            <InlineButtonWrapper>
              <Button
                disabled={type === 'tel' ? !validatePhone(value) : false}
                onClick={onSubmit}
                type="submit"
              >
                Ok
              </Button>
            </InlineButtonWrapper>
          )}
        </TextFieldWrapper>
      </form>
    </>
  );
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TextField;
