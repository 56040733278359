import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track from 'react-tracking';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { SingleDatePicker } from 'react-dates';
import { isInclusivelyAfterDay } from 'lib/utils';

import * as reservationActions from 'store/reservations/actions';
import { availabilitySelector, isLoadingReservationsSelector, optionsSelector } from 'store/reservations/selectors';
import { startDateSelector } from 'store/app/selectors';

import LoadingSpinner from 'components/shared/LoadingSpinner';

import { Title, SubTitle, LoadingText, DatePickerWrapper, Wrapper } from './style';

const mapStateToProps = (state) => ({
  availability: availabilitySelector(state),
  isLoading: isLoadingReservationsSelector(state),
  options: optionsSelector(state),
  startDate: startDateSelector(state),
});

const mapDispatchToProps = {
  fetchAvailability: reservationActions.fetchAvailability,
};

const MOMENT_DATE_FORMAT = 'l';

const SingleDatePickerCalendar = ({
  availability,
  fetchAvailability,
  isLoading,
  onAdvance,
  onChange,
  options,
  startDate,
  title,
  tracking: { trackEvent },
  values: { reservationType },
}) => {
  const [date, setDate] = useState(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (availability && options) {
      onAdvance();
      onChange('selectedDate', Moment(date, MOMENT_DATE_FORMAT));
    }
  }, [availability, options]);

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    fetchAvailability({ selectedDate, typeId: reservationType.id });

    trackEvent({ event: 'Selected date', selectedDate });
  };

  const selectedStartedDate = Moment(`${startDate}`, 'MM-DD-YYYY');
  const today = Moment();
  const startDateOption = selectedStartedDate.isSameOrAfter(today) ? selectedStartedDate : today;

  let content;
  if (isLoading) {
    content = (
      <>
        <LoadingText>Loading your dates...</LoadingText>
        <LoadingSpinner />
      </>
    )
  } else {
    content = (
      <Wrapper>
        <Title>{title}</Title>
        <SubTitle>You can reserve up to 90 days in advance</SubTitle>
        <DatePickerWrapper>
          <SingleDatePicker
            date={date}
            focused={isFocused}
            id="single-date-picker"
            isOutsideRange={(day) =>
              !isInclusivelyAfterDay(day, startDateOption) || isInclusivelyAfterDay(day, Moment().add(90, 'days'))
            }
            numberOfMonths={1}
            onDateChange={(selectedDate) => handleDateChange(selectedDate)}
            onFocusChange={({ focused }) => setIsFocused(focused)}
            placeholder="Pick a Date"
            block
          />
        </DatePickerWrapper>
      </Wrapper>
    )
  }

  return content;
};

SingleDatePickerCalendar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onAdvance: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

const trackedSingleDatePickerCalendar = track({})(SingleDatePickerCalendar);

export default connect(mapStateToProps, mapDispatchToProps)(trackedSingleDatePickerCalendar);
