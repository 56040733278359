import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { auth } from '../../../firebase';
import { SIGNUP_USER, signupUser } from '../actions';

const debug = Debug('sgwa:permit-portal:store:user:sagas:signupUser');

export function* signupUserSaga({ payload: email }) {
  debug('Saga called');

  try {
    const signInMethodsArray = yield call([auth, auth.fetchSignInMethodsForEmail], email);
    debug('List of sign in methods', signInMethodsArray);

    if (!signInMethodsArray.length) {
      debug('Converting user');
      yield call([auth, auth.createUserWithEmailAndPassword], email, '0123456');
    } else {
      debug('User already exists');
    }

    yield put(signupUser.success());
  } catch (e) {
    debug('Saga error', e.message);
    yield put(signupUser.error(e.message));
  }
}

export default function* () {
  yield takeLatest(SIGNUP_USER.PENDING, signupUserSaga);
}
