import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import Config from 'config'; // eslint-disable-line

const SENTRY_DSN_URL = 'https://5af2cde1c54b402db45b5e272c6710ac@o237669.ingest.sentry.io/5553719';

const ignoreErrors = [
  'appCheck/fetch-network-error',
  '@firebase/app-check',
  '@firebase/app-check: FirebaseError: AppCheck: Fetch server returned an HTTP error status. HTTP status: 403. (appCheck/fetch-status-error).',
];

Config.isAnalyticsEnabled &&
  Sentry.init({
    beforeSend(event) {
      if (event && event.message && ignoreErrors.includes(event.message)){
        return null
      }
      
      return event
    },
    dsn: SENTRY_DSN_URL,
    environment: Config.environment,
    ignoreErrors,
    integrations: [
      new Integrations.CaptureConsole({
        levels: ['error'],
      }),
    ],
  });

export default Sentry;
