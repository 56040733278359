import styled from 'styled-components';

export const Wrapper = styled.div`
  align-self: flex-start;
`;

export const SubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 500;
  color: #000000;
  margin: 0 0 6px 0;
`;
