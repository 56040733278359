/* eslint-disable sort-keys */
import Moment from 'moment';

export const isBeforeDay = (a, b) => {
  if (!Moment.isMoment(a) || !Moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
};

export const isInclusivelyAfterDay = (a, b) => {
  if (!Moment.isMoment(a) || !Moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
};

export const generateCalendarDateLabel = (number) => {
  if (number <= 0) return 0;
  if (number >= 99) return '99+';

  return number;
};
