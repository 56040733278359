import styled from 'styled-components';
import { media } from 'styles';

export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  color: #000000;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const CenteredTitle = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  color: #000000;
  text-align: center;
  padding: 0 8px;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const SubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const BoldSubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 400;
  color: #000000;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const LinkTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 400;
  color: #2e5941;
  margin: 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const Wrapper = styled.div`
  max-width: 700px;
  padding: 42px 36px;
`;

export const TextFieldWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid #2e5941;
  width: 100%;

  ${media.min.mobileLandscape`width: 500px;`}
`;

export const TextFieldInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: 0;
  color: #2e5941;
  outline: 0;
  box-shadow: none;
  font-size: 2.2rem;
  font-weight: 500;
  padding: 12px 70px 12px 0;
  ${media.twoDimMin.tablet`font-size: 2.4rem;`}

  &::placeholder {
    color: #d0d0d0;
    font-weight: 400;
  }
`;

export const CancelPageButtonWrapper = styled.div`
  position: absolute;
  display: inline-block;
  bottom: 12px;
  right: 0;
`;

export const ConfirmationPageButtonsWrapper = styled.div`
  float: right;
  margin-top: 12px;
`;

export const ButtonWrapper = styled.div`
  display: inline-block;
  margin-right: 12px;
`;

export const GoBackHomeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
`;
