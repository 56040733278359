import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';

import downloadPDF from 'lib/downloadPDF';

import { reservationSelector, permitPDFSelector } from 'store/reservations/selectors';
import { isIOSChromeSelector } from 'store/userAgent/selectors';

import DocumentSummary from 'components/shared/DocumentSummary';
import Button from 'components/shared/Button';
import { ButtonWrapper, EmailText, BottomWrapper, MadeWithLove, HyperLink, SubTitle, Title, Wrapper } from './style';

const mapStateToProps = (state) => ({
  isIOSChrome: isIOSChromeSelector(state),
  permitPDF: permitPDFSelector(state),
  reservation: reservationSelector(state),
});

export const DocumentIssued = ({ isIOSChrome, permitPDF, reservation, subTitle, title, tracking: { trackEvent } }) => {
  const {
    email: { email },
  } = reservation;

  const handleDownload = () => {
    downloadPDF(permitPDF, isIOSChrome);

    trackEvent({ event: 'Download Button Clicked' });
  };

  const handleFeedbackButtonClick = () => {
    trackEvent({ event: 'Feedback Button Clicked' });
  };

  const handleMadeWithLoveClick = () => {
    trackEvent({ event: 'Made With Love Button Clicked' });
  };

  return (
    <>
      <Wrapper>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <EmailText>{email}</EmailText>
        <DocumentSummary reservation={reservation} />
        <ButtonWrapper>
          <Button type="Download" onClick={handleDownload}>
            Download
          </Button>
        </ButtonWrapper>
      </Wrapper>
      <BottomWrapper>
        <HyperLink href="https://forms.gle/iZzjZfeXteFhZYDE7" target="_blank" onClick={handleFeedbackButtonClick}>
          Feedback or report an issue
        </HyperLink>
        <MadeWithLove>
          Made with{' '}
          <span role="img" aria-label="love">
            ❤️
          </span>{' '}
          by{' '}
          <HyperLink href="https://sauerapple.com/" target="_blank" onClick={handleMadeWithLoveClick}>
            sauerApple
          </HyperLink>
        </MadeWithLove>
      </BottomWrapper>
    </>
  );
};

DocumentIssued.propTypes = {
  isIOSChrome: PropTypes.bool.isRequired,
  permitPDF: PropTypes.string.isRequired,
  reservation: PropTypes.objectOf(PropTypes.any).isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tracking: TrackingPropType,
};

DocumentIssued.defaultProps = {
  tracking: null,
};

const trackedDocumentIssued = track({
  page: 'Document Issued',
})(DocumentIssued);

export default connect(mapStateToProps, null)(trackedDocumentIssued);
