import React from 'react';

import { StyledLink, Text, Title, Wrapper } from './style';

const PermitPortalClosed = () => (
  <Wrapper>
    <Title>The portal is temporarily closed for maintenance.</Title>
    <Text>
      Check our{' '}
      <StyledLink rel="noreferrer" target="_blank" href="https://sgwa.org">
        website
      </StyledLink>{' '}
      for more information, or contact the Mill Creek Ranger Station at (909) 382-2882.
    </Text>
  </Wrapper>
);

export default PermitPortalClosed;
