import styled from 'styled-components';
import { media } from 'styles';

export const Cell = styled.button`
  position: relative;
  width: 15%;
  padding: 14px 0;
  font-weight: 500;
  text-align: center;
  color: #2e5941;
  font-size: 1.6rem;
  background: transparent;
  outline: 0;
  border: 0;
  border-radius: 8px;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  ${media.twoDimMin.tablet`font-size: 1.8rem;`}

  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  ${(p) => p.isDisabled && !p.isSelected && `opacity: 0.5;`}

  &.focus-visible {
    box-shadow: #4e8f6b 0px 0px 0px 2px;
    border-right: 1px solid #4e8f6b;
    border-left: 1px solid #4e8f6b;
  }

  ${(p) =>
    !p.isDisabled &&
    `
  &:hover {
    background: #2E5941;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;

    ${p.isSelected && `color: #2E5941;`}
  }
`}

  ${(p) =>
    p.isSelected &&
    `
    border-radius: 8px;
    background: #2E5941;
    color: #2E5941;

    &:after {
      content: '';
      position: absolute;
      left: 46%;
      top: 30%;
      display: inline-block;    
      transform: rotate(45deg);
      height: 18px;
      width: 10px;
      border-bottom: 4px solid #FFFFFF;
      border-right: 4px solid #FFFFFF;
    }
  `}
`;

export const OvernightCell = styled.button`
  position: relative;
  width: 15%;
  padding: 14px 0;
  font-weight: 500;
  text-align: center;
  color: #2e5941;
  font-size: 1.6rem;
  background: transparent;
  outline: 0;
  border: 0;
  border-radius: 8px;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  ${media.twoDimMin.tablet`font-size: 1.8rem;`}

  cursor: ${({ isDisabled, isEnd }) => (isEnd ? 'pointer' : isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled, isEnd }) => (isEnd ? '1' : isDisabled ? '0.5' : '1')};

  &:focus-visible {
    box-shadow: #4e8f6b 0px 0px 0px 2px;
    border-right: 1px solid #4e8f6b;
    border-left: 1px solid #4e8f6b;
  }

  &:hover {
    background: ${({ isDisabled, isEnd, isStart }) => 
      isEnd ? '#2E5941' : isDisabled ? 'transparent' : (isStart || isEnd) ? '#2E5941' : '#2E5941'};
    color: ${({ isDisabled, isEnd, isStart }) => 
      isEnd ? '#2E5941' : isDisabled ? '#2e5941' : (isStart || isEnd) ? '#2E5941' : '#fff'};
    cursor: ${({ isDisabled, isEnd }) => (isEnd ? 'pointer' : isDisabled ? 'not-allowed' : 'pointer')};
  }

  ${({ isEnd, isStart }) => (isStart || isEnd) && `
    &:after {
      content: '';
      position: absolute;
      left: 46%;
      top: 30%;
      display: inline-block;    
      transform: rotate(45deg);
      height: 18px;
      width: 10px;
      border-bottom: 4px solid #FFFFFF;
      border-right: 4px solid #FFFFFF;
    }
  `}

  ${({ isDisabled, isStart }) =>
    isStart && !isDisabled &&
    `
    border-radius: 8px;
    background: #2E5941;
    color: #2E5941;
  
    &:before {
      content: '';
      position: absolute;
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent transparent transparent #2E5941;;
      border-style: solid;
      border-width: 2rem 0 2rem 1rem;
      height: 0;
      width: 0;
      z-index: 1;
    }
  `}

  ${({ isEnd }) =>
    isEnd && `
    border-radius: 8px;
    background: #2E5941;
    color: #2E5941;

    &:before {
      content: '';
      position: absolute;
      left: -1rem;
      top: 50%;
      transform: translateY(-50%);
      border-color: transparent #2E5941; transparent transparent;
      border-style: solid;
      border-width: 2rem 1rem 2rem 0;
      height: 0;
      width: 0;
      z-index: 1;
    }
  `}

  ${({ isBetweenDates }) =>
    isBetweenDates &&
    `
    border-radius: 8px;
    background: #498F63;
    color: #fff;
  `}
`;