import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonWrapper } from './style';

const MultipleChoice = ({ isDisabled, onClick, options }) => (
  <ButtonWrapper>
    {options.map((option, index) => (
      <Button disabled={isDisabled} key={option.id} onClick={() => onClick(index)}>
        {option.title}
      </Button>
    ))}
  </ButtonWrapper>
);

MultipleChoice.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
};

MultipleChoice.defaultProps = {
  isDisabled: false,
};

export default MultipleChoice;
