import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import Button from 'components/shared/Button';
import {
  Checkbox,
  CheckboxLabel,
  CheckboxWrapper,
  RulesButtonWrapper,
  SubTitle,
  TermsWrapper,
  Title,
} from './style';

const RulesConfirmation = ({ id, onChange, onSubmit, subTitle, terms, title }) => {
  const [isRulesChecked, setIsRulesChecked] = useState(false);

  const fieldRef = useRef();

  useEffect(() => {
    fieldRef.current.focus();
  }, []);

  const handleChange = () => {
    const timestamp = Moment().format('MMMM Do YYYY, h:mm:ss a');

    setIsRulesChecked((prevIsRulesChecked) => !prevIsRulesChecked);
    onChange([id], timestamp);
  };

  return (
    <>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <TermsWrapper ref={fieldRef}>
        {terms.map((term, index) => {
          if (index === 0)
            return (
              <p key={term}>
                <b>{term}</b>
              </p>
            );
          if (terms.length - 1 === index)
            return (
              <p key={term}>
                <b>{term}</b>
              </p>
            );
          return <p key={term}>{term}</p>;
        })}
      </TermsWrapper>
      <CheckboxWrapper>
        <Checkbox
          type="checkbox"
          checked={isRulesChecked}
          id="isRulesChecked"
          name="isRulesChecked"
          onChange={handleChange}
        />
        <CheckboxLabel htmlFor="isRulesChecked">
          I have read and understand the rules and regulations. I agree to them by checking this
          box.
        </CheckboxLabel>
      </CheckboxWrapper>
      <form onSubmit={onSubmit}>
        <RulesButtonWrapper>
          <Button type="submit" disabled={!isRulesChecked} onClick={onSubmit}>
            Continue
          </Button>
        </RulesButtonWrapper>
      </form>
    </>
  );
};

RulesConfirmation.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  subTitle: PropTypes.string.isRequired,
  terms: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default RulesConfirmation;
