import styled from 'styled-components';
import { media } from 'styles';

export const UserFormWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  padding: 0 20px;
  width: 100%;
  ${media.min.mobileLandscape`width: 500px;`}

  &.page-enter {
    opacity: 0;
  }
  &.page-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  &.page-exit {
    opacity: 1;
  }
  &.page-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`;

export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 6px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const SubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 500;
  color: #000000;
  margin: 0 0 26px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

// TextField

export const TextFieldWrapper = styled.div`
  border-bottom: 1px solid #2e5941;
  width: 100%;
  position: relative;

  ${media.min.mobileLandscape`width: 500px;`}
`;

export const TextFieldInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: 0;
  color: #2e5941;
  outline: 0;
  box-shadow: none;
  font-size: 2.2rem;
  font-weight: 500;
  padding: ${(p) => (p.allowFullText ? '12px 12px 12px 0;' : '12px 100px 12px 0')};

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}

  &::placeholder {
    color: #d0d0d0;
    font-weight: 400;
  }
`;

export const InlineButtonWrapper = styled.div`
  position: absolute;
  display: inline-block;
  bottom: 12px;
  right: 0;
`;

// Text Field Number

export const TextFieldNumberInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: 0;
  color: #2e5941;
  outline: 0;
  box-shadow: none;
  font-size: 2.2rem;
  font-weight: 500;
  padding: 12px 100px 12px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}

  &::placeholder {
    color: #d0d0d0;
  }
`;

export const TextFieldNumberWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid #2e5941;
  width: 100%;
`;

export const NumberFieldHelperText = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: #969696;
  margin: 12px 0;

  ${media.twoDimMin.tablet`font-size: 1.6rem;`}
`;

// Buttons

export const ArrowButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ArrowButton = styled.button`
  padding: 10px 0px 0px 10px;
  width: 40px;
  height: 45px;
  background: #2e5941;
  outline: 0;
  border: 1px solid #000000;
  border-radius: 3px;

  &.focus-visible {
    background: #4e8f6b;
    border: 1px solid #fff;
  }
  &:hover {
    background: #4e8f6b;
    border: 1px solid #fff;
  }
  &:last-child {
    margin-left: 8px;
  }
  ${(p) => p.disabled && 'opacity: 0.5;'}
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};

  &:after {
    content: '';
    display: inline-block;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    margin-top: -4px;

    ${(p) => (p.isForward ? 'border-left: 10px solid #fff;' : 'border-right: 10px solid #fff;')}
    ${(p) => (p.isForward ? 'margin-left: -6px;' : 'margin-right: 12px;')}
  }
`;

export const BottomWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  margin-bottom: 28px;

  ${media.min.mobileLandscape`flex-direction: row;`}
`;

export const ProgressBarWrapper = styled.div`
  margin-right: 20px;
`;

export const ProgressBarPercentage = styled.span`
  font-size: 1.4rem;
  line-height: 16px;

  ${media.twoDimMin.tablet`font-size: 1.6rem;`}
`;

export const ProgressBar = styled.div`
  position: relative;
  height: 6px;
  display: block;
  width: 260px;
  background-color: #efefef;
  border-radius: 2px;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    background-color: #2e5941;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${(p) => p.width && `calc(${p.width} * 260 * 1px);`};
  }
`;

export const MultiFieldWrapper = styled.div`
  padding: 40px 20px;
`;

export const MultiPromptWrapper = styled.div`
  margin-top: 12px;
`;

export const MultiFieldButtonWrapper = styled.div`
  margin-top: 30px;
  bottom: 12px;
  right: 0;
`;

// Rules Confirmation

export const TermsWrapper = styled.div`
  overflow-y: scroll;
  border-radius: 8px;
  border: solid 2px #dbe1e8;
  padding: 18px;
  max-height: 350px;

  ${media.min.desktop`padding: 20px;`}
`;

export const RulesButtonWrapper = styled.div`
  bottom: 12px;
  right: 0;
  margin-top: 30px;
  text-align: center;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  padding-left: 8px;
`;

export const Checkbox = styled.input`
  border: 1px solid #000000;
  border-radius: 3px;
  width: 30px;
  height: 30px;

  ${media.twoDimMin.tablet`
    width: 38px;
    height: 38px;
  `}
`;

export const CheckboxLabel = styled.label`
  margin-left: 16px;
  color: #000000;
  font-weight: 500;
  font-size: 1.6rem;

  ${Checkbox}:focus ~ &:before {
    border: 1px solid #2e5941;
    box-shadow: 0px 0px 5px #2e5941;
    outline: none;
  }
`;
