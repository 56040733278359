import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/shared/Button';
import {
  InlineButtonWrapper,
  NumberFieldHelperText,
  SubTitle,
  TextFieldNumberInput,
  TextFieldNumberWrapper,
  Title,
} from './style';

const TextFieldNumber = ({ id, onChange, onSubmit, subTitle, title, type, value }) => {
  const field = useRef();

  useEffect(() => {
    field.current.focus();
  }, []);

  const isValid = (number) => !!number && number > 0 && number <= 12;
  return (
    <>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <form>
        <TextFieldNumberWrapper>
          <TextFieldNumberInput
            id={id}
            max="12"
            min="1"
            name={id}
            onChange={(event) => onChange([id], event.target.value)}
            placeholder="--"
            required
            type={type}
            value={value}
            ref={field}
          />
          <InlineButtonWrapper>
            <Button type="submit" disabled={!isValid(value)} onClick={onSubmit}>
              Ok
            </Button>
          </InlineButtonWrapper>
        </TextFieldNumberWrapper>
        <NumberFieldHelperText>12 people allowed per permit</NumberFieldHelperText>
      </form>
    </>
  );
};

TextFieldNumber.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default TextFieldNumber;
