/* eslint-disable sort-keys  */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import track, { TrackingPropType } from 'react-tracking';

import * as StepTypes from 'constants/stepTypes';

import { isTouchScreenSelector } from 'store/userAgent/selectors';
import { mainStepsSelector } from 'store/app/selectors';
import { reservationsErrorSelector, didFetchMainCollectionSelector } from 'store/reservations/selectors';

import DeviceOrientationObserver from 'components/shared/DeviceOrientationObserver';
import WindowSizeObserver from 'components/shared/WindowSizeObserver';
import Layout from 'components/shared/Layout';
import LinkButton from 'components/shared/LinkButton';

import LocationStep from './Steps/LocationStep';
import StartStep from './Steps/StartStep';
import SingleDatePickerCalendar from './Steps/SingleDatePickerCalendar';
import AvailabilityCalendar from './Steps/AvailabilityCalendar';
import UserInformationForm from './Steps/UserForm';
import UserConfirmation from './Steps/UserConfirmation';
import DocumentIssued from './Steps/DocumentIssued';
import DropdownOptions from './Steps/DropdownOptions';

import { ResetButtonWrapper } from './style';

const componentMapping = {
  [StepTypes.LOCATION_STEP]: LocationStep,
  [StepTypes.START_STEP]: StartStep,
  [StepTypes.SINGLE_DATE_PICKER_CALENDAR]: SingleDatePickerCalendar,
  [StepTypes.DAY_CALENDAR]: AvailabilityCalendar,
  [StepTypes.OVERNIGHT_CALENDAR]: AvailabilityCalendar,
  [StepTypes.USER_INFORMATION_FORM]: UserInformationForm,
  [StepTypes.USER_CONFIRMATION]: UserConfirmation,
  [StepTypes.DOCUMENT_ISSUED]: DocumentIssued,
  [StepTypes.TRAILHEAD_DROPDOWN]: DropdownOptions,
};

const mapStateToProps = (state) => ({
  allStepsMetadata: mainStepsSelector(state),
  availabilityError: reservationsErrorSelector(state),
  didFetchMainCollection: didFetchMainCollectionSelector(state),
  isTouchScreen: isTouchScreenSelector(state),
});

const mapDispatchToProps = {};

export const Main = ({
  allStepsMetadata,
  availabilityError,
  didFetchMainCollection,
  isTouchScreen,
  tracking: { trackEvent },
}) => {
  const [values, setValues] = useState({});
  const { reservationType } = values;

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [stepIds, setStepIds] = useState([StepTypes.LOCATION_STEP]);

  const handleAdvance = () => setCurrentStepIndex((currentStep) => currentStep + 1);

  useEffect(() => {
    if (reservationType) {
      setStepIds((prevStepIds) => [...prevStepIds, ...reservationType.steps]);
      handleAdvance();
    }
  }, [reservationType]);

  useEffect(() => {
    if (didFetchMainCollection) {
      setStepIds((prevStepIds) => [...prevStepIds, StepTypes.START_STEP]);
      handleAdvance();
    }
  }, [didFetchMainCollection]);

  useEffect(() => {
    if (availabilityError) {
      const error = availabilityError;

      toast.error(error);
      trackEvent({ event: 'Error Toast Shown', error });
    }
  }, [availabilityError]);

  const handleChange = (name, value) => {
    setValues((preValues) => ({
      ...preValues,
      [name]: value,
    }));
  };

  const handleStartOver = () => {
    setCurrentStepIndex(0);
    setStepIds([StepTypes.LOCATION_STEP]);
    setValues({});

    trackEvent({ event: 'Resetting Permit Button Clicked' });
  };

  const currentStepId = stepIds[currentStepIndex];
  const currentStepData = allStepsMetadata[currentStepId];
  const StepComponent = componentMapping[currentStepId];

  const content = (
    <Layout>
      <StepComponent
        isTouchScreen={isTouchScreen}
        onAdvance={handleAdvance}
        onChange={handleChange}
        values={values}
        {...currentStepData}
      />
      {currentStepIndex !== 0 && (
        <ResetButtonWrapper>
          <LinkButton isBiggerText onClick={handleStartOver}>
            Reset Form
          </LinkButton>
        </ResetButtonWrapper>
      )}
    </Layout>
  );

  return (
    <>
      {isTouchScreen ? (
        <DeviceOrientationObserver isLandscapeMode={currentStepData.isLandscapeMode}>
          {content}
        </DeviceOrientationObserver>
      ) : (
        <WindowSizeObserver>{content}</WindowSizeObserver>
      )}
    </>
  );
};

Main.propTypes = {
  allStepsMetadata: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
  availabilityError: PropTypes.string,
  didFetchMainCollection: PropTypes.bool.isRequired,
  isTouchScreen: PropTypes.bool.isRequired,
  tracking: TrackingPropType,
};

Main.defaultProps = {
  availabilityError: null,
  tracking: null,
};

const trackedMain = track({
  page: 'Main',
})(Main);

export default connect(mapStateToProps, mapDispatchToProps)(trackedMain);
