import React from 'react';

import styled from 'styled-components';
import { media } from 'styles';

const StyledLinkButton = styled.button`
  font-size: ${(p) => (p.isBiggerText ? '1.6rem' : '1.4rem')};
  outline: 0;
  background: transparent;
  border: 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  padding-bottom: 6px;
  font-weight: 600;
  color: #2C2C2C;

  ${media.twoDimMin.tablet`
    font-size: ${(p) => (p.isBiggerText ? '1.8rem' : '1.6rem')};
  `}

  &.focus-visible {
    border-bottom: 2px solid #2C2C2C;
  } 

  &:hover {
    ${(p) => !p.disabled && `border-bottom: 2px solid #2C2C2C;`}
  }

  ${(p) =>
    p.disabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

const LinkButton = ({ children, disabled = false, onClick, isBiggerText = false }) => (
  <StyledLinkButton isBiggerText={isBiggerText} type="button" onClick={onClick} disabled={disabled}>
    {children}
  </StyledLinkButton>
);

export default LinkButton;
