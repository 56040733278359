/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/shared/Button';
import {
  SubTitle,
  TextFieldInput,
  TextFieldWrapper,
  Title,
  MultiFieldWrapper,
  MultiPromptWrapper,
  MultiFieldButtonWrapper,
} from './style';

const MultiTextField = ({ id, onChange, onSubmit, prompts, subTitle, title, value }) => {
  const field = useRef();

  useEffect(() => {
    field.current.focus();
  }, []);

  const handleChange = (promptId, promptValue) => {
    onChange([id], {
      ...value,
      [promptId]: promptValue,
    });
  };

  const areAnyFieldsIncomplete = prompts.some((prompt) => {
    if (!prompt.required) return false;
    return !value[prompt.id];
  });
  return (
    <MultiFieldWrapper>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      {prompts.map((prompt, index) => (
        <MultiPromptWrapper key={prompt.id}>
          <TextFieldWrapper>
            <TextFieldInput
              id={prompt.id}
              name={prompt.id}
              onChange={(event) => handleChange([prompt.id], event.target.value)}
              placeholder={prompt.placeholder}
              required
              type={prompt.type}
              value={value[prompt.id] || ''}
              ref={index === 0 ? field : null}
              allowFullText
            />
          </TextFieldWrapper>
        </MultiPromptWrapper>
      ))}
      <form onSubmit={onSubmit}>
        <MultiFieldButtonWrapper>
          <Button type="submit" disabled={areAnyFieldsIncomplete} onClick={onSubmit}>
            Ok
          </Button>
        </MultiFieldButtonWrapper>
      </form>
    </MultiFieldWrapper>
  );
};

MultiTextField.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  prompts: PropTypes.arrayOf(PropTypes.object).isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
};

MultiTextField.defaultProps = {
  value: '',
};

export default MultiTextField;
