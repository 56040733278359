import styled from 'styled-components';
import { colors, media, type } from 'styles';

export const WindowSizeObserverWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const NotSupportedMessage = styled.div`
  position: fixed;
  background: #2E5941 center;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 64px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.4rem;
  color: ${colors.white};
  opacity: 1;

  ${media.twoDimMin.tablet`
    visibility: hidden;
    opacity: 0;
  `}
`;

export const MessageHeader = styled.h3`
  font-weight: ${type.weight.bold};
  color: #fff;
  font-size: 1.6rem;
`;
