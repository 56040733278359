import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  isLoadingReservationsSelector,
  trailheadsAvailabilitySelector,
} from 'store/reservations/selectors';

import TypeAheadPrompt from 'components/shared/TypeAheadPrompt';
import { SubTitle, Title, Wrapper } from './style';

const mapStateToProps = (state) => ({
  availableOptions: trailheadsAvailabilitySelector(state),
  isLoading: isLoadingReservationsSelector(state),
});

export const DropdownOptions = ({ availableOptions, onAdvance, onChange, subTitle, title }) => {
  const handleChange = (option) => {
    onChange('selectedTrailhead', option);
    onAdvance();
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <TypeAheadPrompt
        onChange={handleChange}
        options={availableOptions}
        placeholder="Click here to start"
      />
    </Wrapper>
  );
};

DropdownOptions.propTypes = {
  availableOptions: PropTypes.arrayOf(PropTypes.string),
  onAdvance: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

DropdownOptions.defaultProps = {
  availableOptions: [],
};

export default connect(mapStateToProps)(DropdownOptions);
