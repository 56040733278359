import Debug from 'debug';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { locationTypeBackendIdSelector } from '../selectors';

import { FETCH_RESERVATION_OPTIONS, fetchReservationOptions } from '../actions';
import { db } from '../../../firebase';

const debug = Debug('sgwa:permit-portal:store:reservations:sagas:fetchReservationOptions');

export function* fetchReservationOptionsSaga({ payload: reservationTypeId }) {
  debug('Saga called', reservationTypeId);

  try {
    const backendLocationId = yield select(locationTypeBackendIdSelector);

    const reservationOptionsRef = db.collection(`${backendLocationId}`).doc(`${reservationTypeId}`);
    const reservationOptions = yield call([reservationOptionsRef, reservationOptionsRef.get]);

    const optionsData = reservationOptions.data();
    debug('Reservation options', optionsData);

    yield put(fetchReservationOptions.success(optionsData));
  } catch (e) {
    debug('Saga error', e.message);
    yield put(fetchReservationOptions.error(e.message));
  }
}

export default function* () {
  yield takeLatest(FETCH_RESERVATION_OPTIONS.PENDING, fetchReservationOptionsSaga);
}
