import Debug from 'debug';
import { select, call, put, takeLatest } from 'redux-saga/effects';

import Config from 'config'; // eslint-disable-line
import awaitAsyncAction from 'lib/awaitAsyncAction';
import { locationTypeTitles } from 'constants/locationTypes';

import { customNoteSelector } from 'store/app/selectors';
import { signupUser } from 'store/app/actions';

import { locationTypeBackendIdSelector } from '../selectors';
import { generateBatchDatesReservation, generateBatchEmail, generateBatchUserReservation } from './reservationUtils';

import {
  CHECK_RESERVATION_AVAILABILITY,
  checkReservationAvailability,
  GENERATE_PERMIT,
  generatePermit,
  SUBMIT_RESERVATION,
  submitReservation,
} from '../actions';
import { auth, db } from '../../../firebase';

const debug = Debug('sgwa:permit-portal:store:reservations:sagas:submitReservation');

export function* submitReservationSaga({ payload }) {
  debug('Saga called', payload);
  const {
    email,
    locationType,
    reservation: { selection },
    reservationType,
    selectedTrailhead,
    user,
  } = payload;

  const batch = db.batch();
  try {
    const backendLocationId = yield select(locationTypeBackendIdSelector);
    const locationTitle = `${locationTypeTitles[locationType]}`;

    yield put(checkReservationAvailability({ ...payload, backendLocationId }));
    const [, availabilityError] = yield call(awaitAsyncAction, CHECK_RESERVATION_AVAILABILITY);

    if (availabilityError) throw availabilityError.payload;

    yield* generateBatchDatesReservation(batch, { ...payload, backendLocationId });

    const permitNumber = yield call(generateBatchUserReservation, batch, { ...payload, backendLocationId });
    const customNote = yield select(customNoteSelector);

    const reservationPayload = {
      customNote,
      email,
      locationTitle,
      locationType: `${backendLocationId}`,
      permitNumber,
      reservationType: reservationType.id,
      selectedTrailhead,
      selection,
      user,
    };

    yield put(generatePermit(reservationPayload));
    const [permitSuccess, permitError] = yield call(awaitAsyncAction, GENERATE_PERMIT);

    if (permitError) throw permitError.payload;

    const emailPDF = `data:application/pdf;base64,${permitSuccess.payload}`;
    yield call(generateBatchEmail, batch, {
      ...reservationPayload,
      pdf: emailPDF,
    });

    yield call([batch, batch.commit]);
    debug('Batch call successfully made');

    if (email.isEmailSignedUp && auth.currentUser && auth.currentUser.isAnonymous) yield put(signupUser(email.email));

    yield put(
      submitReservation.success({
        email,
        locationType: `${backendLocationId}`,
        permitNumber,
        reservationType: reservationType.id,
        selectedTrailhead,
        selection,
        user,
      })
    );
  } catch (e) {
    debug('Saga error', e.message);
    yield put(submitReservation.error(e.message));
  }
}

export default function* () {
  yield takeLatest(SUBMIT_RESERVATION.PENDING, submitReservationSaga);
}
