/* eslint-disable sort-keys */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';

import { toast } from 'react-toastify';
import history from 'lib/history';

import * as LocationTypes from 'constants/locationTypes';

import MultipleChoice from 'components/shared/MultipleChoice';

import * as reservationActions from 'store/reservations/actions';
import * as appActions from 'store/app/actions';

import { isCucamongaEnabledSelector, isSanGorgonioEnabledSelector, isFetchingSelector } from 'store/app/selectors';

import LinkButton from 'components/shared/LinkButton';
import LoadingSpinner from 'components/shared/LoadingSpinner';

import {
  CancelButtonWrapper,
  LoadingText,
  LocationStepWrapper,
  MadeWithLove,
  BottomWrapper,
  Title,
  HyperLink,
} from './style';

const mapStateToProps = (state) => ({
  isCucamongaEnabled: isCucamongaEnabledSelector(state),
  isSanGorgonioEnabled: isSanGorgonioEnabledSelector(state),
  isFetching: isFetchingSelector(state),
});

const mapDispatchToProps = {
  fetchMainCollection: appActions.fetchMainCollection,
  resetReservation: reservationActions.resetReservation,
};

const LocationStep = ({
  fetchMainCollection,
  isCucamongaEnabled,
  isFetching,
  isSanGorgonioEnabled,
  onChange,
  options,
  resetReservation,
  title,
  tracking: { trackEvent },
}) => {
  useEffect(() => {
    resetReservation();
  }, []);

  const handleChange = (optionIndex) => {
    const { id } = options[optionIndex];

    if (id === LocationTypes.SAN_GORGONIO_WILDERNESS_ID && !isSanGorgonioEnabled) {
      toast.error(
        'Sorry, the San Gorgonio Wilderness is currently closed. Please visit sgwa.org for more information.'
      );
    } else if (id === LocationTypes.CUCAMONGA_WILDERNESS_ID && !isCucamongaEnabled) {
      toast.error('Sorry, the Cucamonga Wilderness is currently closed. Please visit sgwa.org for more information.');
    } else {
      onChange('locationType', id);
      fetchMainCollection(id);

      trackEvent({ event: 'Location Type Selected', id });
    }
  };

  const handleCancelButtonClick = () => {
    history.push('/cancel');

    trackEvent({ event: 'Cancel Existing Reservation Link Clicked' });
  };

  const handleFeedbackButtonClick = () => {
    trackEvent({ event: 'Feedback Button Clicked' });
  };

  const handleMadeWithLoveClick = () => {
    trackEvent({ event: 'Made With Love Button Clicked' });
  };

  return (
    <>
      {isFetching ? (
        <>
          <LoadingText>Fetching Wilderness Data...</LoadingText>
          <LoadingSpinner />
        </>
      ) : (
        <>
          <LocationStepWrapper>
            <Title>{title}</Title>
            <MultipleChoice onClick={handleChange} options={options} />
          </LocationStepWrapper>
          <CancelButtonWrapper>
            <LinkButton isBiggerText onClick={handleCancelButtonClick}>
              Cancel Reservation
            </LinkButton>
          </CancelButtonWrapper>
          <BottomWrapper>
            <HyperLink href="https://forms.gle/iZzjZfeXteFhZYDE7" target="_blank" onClick={handleFeedbackButtonClick}>
              Feedback or issue
            </HyperLink>
            <MadeWithLove>
              Made by{' '}
              <HyperLink href="https://sauerapple.com/" target="_blank" onClick={handleMadeWithLoveClick}>
                sauerApple
              </HyperLink>
            </MadeWithLove>
          </BottomWrapper>
        </>
      )}
    </>
  );
};

LocationStep.propTypes = {
  fetchMainCollection: PropTypes.func.isRequired,
  isCucamongaEnabled: PropTypes.bool.isRequired,
  isSanGorgonioEnabled: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  resetReservation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tracking: TrackingPropType,
};

LocationStep.defaultProps = {
  tracking: null,
};

const trackedLocationStep = track({})(LocationStep);

export default connect(mapStateToProps, mapDispatchToProps)(trackedLocationStep);
