/* eslint-disable react/forbid-prop-types */

import React from 'react';
import * as PermitTypes from 'constants/permitTypes';

import DayTableCell from './DayTableCell';
import OvernightTableCell from './OvernightTableCell';

const componentMapping = {
  [PermitTypes.DAY_ID]: DayTableCell,
  [PermitTypes.OVERNIGHT_ID]: OvernightTableCell,
};

const TableCell = ({
  furthestStartDate,
  headerItems,
  isLoading,
  onUserSelection,
  reservationTypeId,
  selection,
  site,
}) => {
  const CellComponent = componentMapping[reservationTypeId];

  return (
    <CellComponent
      furthestStartDate={furthestStartDate}
      headerItems={headerItems}
      isLoading={isLoading}
      onUserSelection={onUserSelection}
      selection={selection}
      site={site}
    />
  );
};

TableCell.propTypes = {};

TableCell.defaultProps = {};

export default TableCell;
