/* eslint-disable brace-style */

import Debug from 'debug';
import { call, put } from 'redux-saga/effects';
import UAParser from 'ua-parser-js';

import 'lib/sentry';
import awaitAsyncAction from 'lib/awaitAsyncAction';
import { getQueryParams } from 'lib/queryParams';

import { setUserAgent } from 'store/userAgent/actions';
import { FETCH_PORTAL_COLLECTION, fetchPortalCollection, initializeApp, LOGIN_USER, loginUser } from '../actions';

const debug = Debug('sgwa:permit-portal:store:app:sagas:sessionManager');

function* setUserAgentAction(queryParams) {
  const userAgent = UAParser();
  yield put(
    setUserAgent({
      deviceTypeOverride: queryParams.d,
      userAgent,
    })
  );
}

function* validateUser() {
  yield put(loginUser());
  const [, errorAction] = yield call(awaitAsyncAction, LOGIN_USER);

  if (errorAction) {
    const { payload: error } = errorAction;
    if (error) throw error;
  }
}

function* fetchPortalData() {
  yield put(fetchPortalCollection());
  const [, errorAction] = yield call(awaitAsyncAction, FETCH_PORTAL_COLLECTION);

  if (errorAction) {
    const { payload: error } = errorAction;
    if (error) throw error;
  }
}

export default function* initSessionManager() {
  yield put(initializeApp());
  debug('Saga called');

  const queryParams = yield call(getQueryParams);
  yield* setUserAgentAction(queryParams);

  try {
    yield* validateUser();

    yield* fetchPortalData();

    yield put(initializeApp.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(initializeApp.error(e));
  }
}
