import { all, call, select } from 'redux-saga/effects';
import { customAlphabet } from 'nanoid';
import Moment from 'moment';

import { overnightAvailabilityTableSelector, queriedDatesSelector } from 'store/reservations/selectors';

import { trackUser } from 'lib/analytics';
import generateEmail from 'lib/generateEmail';
import backendIds from 'constants/backendIds';
import * as PermitTypes from 'constants/permitTypes';

import { firebaseInstance, db } from '../../../firebase';

export const generateCancelCountBatch = (batch, locationType) => {
  const dateKey = Moment().format('YYYY-MM-DD');
  const queryDate = Moment().toDate();

  const data = {
    cancelCount: firebaseInstance.firestore.FieldValue.increment(1),
    timeStamp: queryDate,
  };

  const eventRef = db.collection(`${locationType}`).doc('main').collection('events').doc(dateKey);
  batch.set(eventRef, data, { merge: true });
};

const generateReservationCountBatch = (batch, backendLocationId) => {
  const dateKey = Moment().format('YYYY-MM-DD');
  const queryDate = Moment().toDate();

  const data = {
    reservationCount: firebaseInstance.firestore.FieldValue.increment(1),
    timeStamp: queryDate,
  };

  const eventRef = db.collection(`${backendLocationId}`).doc('main').collection('events').doc(dateKey);
  batch.set(eventRef, data, { merge: true });
};

export const generateBatchUserReservation = (batch, payload) => {
  const {
    backendLocationId,
    email,
    reservation: { backendId, queryDate, selection },
    reservationType,
    selectedTrailhead,
    user,
  } = payload;

  const nanoid = customAlphabet('0123456789ABCDEFGJKLMNOPQRSTUVWXYZ', 8);
  const permitNumber = nanoid();

  const data = {
    ...email,
    backendId,
    isCancelled: false,
    locationType: `${backendLocationId}`,
    permitNumber,
    queryDate,
    reservationType: reservationType.id,
    selection,
    user,
    ...(selectedTrailhead && { selectedTrailhead }),
  };

  const reservationRef = db.collection(`${backendLocationId}`).doc('main').collection('reservations').doc(permitNumber);
  batch.set(reservationRef, data, { merge: true });

  generateReservationCountBatch(batch, backendLocationId);

  trackUser({ user: { email: email.email } });

  return permitNumber;
};

export const generateBatchEmail = (batch, payload) => {
  const {
    email: { email },
    pdf,
    permitNumber,
  } = payload;

  const data = generateEmail(pdf, payload);

  const ref = db.collection('mail').doc(`${email}-${permitNumber}`);
  batch.set(ref, data, { merge: true });
};

const decrementSiteRemainingPayload = ({ date, id, remaining }) => ({
  [id]: {
    [date]: { remaining: remaining - 1 },
  },
});

export const enumerateBetweenDates = (start, end, availability, batch, reservationTypeId, backendLocationId) => {
  const { date, id, total } = start;

  const momentStart = Moment(date);
  const now = momentStart.clone();

  while (now.isBefore(end.date)) {
    const dateKey = now.format('YYYY-MM-DD');
    const queryMonth = Moment(dateKey).clone().format('MMMM-YYYY');

    let remaining;
    availability && availability[dateKey] ? ({ remaining } = availability[dateKey]) : (remaining = total);

    const overnightData = decrementSiteRemainingPayload({ date: dateKey, id, remaining });

    const ref = db.doc(`${backendLocationId}/${reservationTypeId}`).collection('reservations').doc(queryMonth);
    batch.set(ref, overnightData, { merge: true });

    now.add(1, 'days');
  }
};

const generateOvernightPayload = (backendLocationId, selection, availability, batch, reservationTypeId) => {
  Object.keys(selection).forEach((siteId) => {
    const { end, start } = selection[siteId];
    const siteAvailability = availability.filter((site) => site.id === start.id)[0].dates;

    enumerateBetweenDates(start, end, siteAvailability, batch, reservationTypeId, backendLocationId);
  });
};

export function* generateBatchDatesReservation(batch, payload) {
  const {
    backendLocationId,
    reservation: { backendId, selection },
    reservationType,
  } = payload;

  const reservationTypeId = backendIds[reservationType.id];

  if (reservationType.id === PermitTypes.DAY_ID) {
    const dayData = decrementSiteRemainingPayload(selection);

    const ref = db.doc(`${backendLocationId}/${reservationTypeId}`).collection('reservations').doc(backendId);
    batch.set(ref, dayData, { merge: true });
  } else {
    const overnightAvailability = yield select(overnightAvailabilityTableSelector);
    generateOvernightPayload(backendLocationId, selection, overnightAvailability, batch, reservationTypeId);
  }
}

export function batchCancelDayReservation(batch, userReservation) {
  const { locationType, permitNumber, reservationType, selection } = userReservation;
  const reservationTypeId = backendIds[reservationType];

  let date;
  let id;
  let backendId;

  if (userReservation.date) {
    ({ id } = userReservation.trail);
    date = Moment(userReservation.date.utc).format('YYYY-MM-DD');
    backendId = userReservation.docId;
  } else {
    ({ backendId } = userReservation);
    ({ id } = selection);
    ({ date } = selection);
  }

  const updatePayload = {
    [id]: {
      [date]: {
        remaining: firebaseInstance.firestore.FieldValue.increment(1),
      },
    },
  };

  const ref = db.doc(`${locationType}/${reservationTypeId}`).collection('reservations').doc(backendId);
  batch.set(ref, updatePayload, { merge: true });

  const userReservationRef = db.collection(`${locationType}`).doc('main').collection('reservations').doc(permitNumber);
  batch.set(userReservationRef, { ...userReservation, isCancelled: true }, { merge: true });
}

export function batchCancelOvernightReservation(batch, userReservation) {
  const { locationType, permitNumber, reservationType, selection } = userReservation;
  const reservationTypeId = backendIds[reservationType];

  if (userReservation.date) {
    Object.keys(userReservation.date).forEach((siteId) => {
      const { end, start } = userReservation.date[siteId].dates;

      const momentStart = Moment(start);
      const now = momentStart.clone();

      while (now.isBefore(end)) {
        const dateKey = now.format('YYYY-MM-DD');
        const queryMonth = Moment(dateKey).clone().format('MMMM-YYYY');

        const updatePayload = {
          [siteId]: {
            [dateKey]: {
              remaining: firebaseInstance.firestore.FieldValue.increment(1),
            },
          },
        };

        const ref = db.doc(`${locationType}/${reservationTypeId}`).collection('reservations').doc(queryMonth);
        batch.set(ref, updatePayload, { merge: true });

        now.add(1, 'days');
      }
    });
  } else {
    Object.keys(selection).forEach((siteId) => {
      const { end, start } = selection[siteId];
      const { date, id } = start;

      const momentStart = Moment(date);
      const now = momentStart.clone();

      while (now.isBefore(end.date)) {
        const dateKey = now.format('YYYY-MM-DD');
        const queryMonth = Moment(dateKey).clone().format('MMMM-YYYY');

        const updatePayload = {
          [id]: {
            [dateKey]: {
              remaining: firebaseInstance.firestore.FieldValue.increment(1),
            },
          },
        };

        const ref = db.doc(`${locationType}/${reservationTypeId}`).collection('reservations').doc(queryMonth);
        batch.set(ref, updatePayload, { merge: true });

        now.add(1, 'days');
      }
    });
  }

  const userReservationRef = db.collection(`${locationType}`).doc('main').collection('reservations').doc(permitNumber);
  batch.set(userReservationRef, { ...userReservation, isCancelled: true }, { merge: true });
}

export function* checkDayReservationAvailability(backendLocationId, reservationTypeId, backendId, selection, debug) {
  const ref = db.doc(`${backendLocationId}/${reservationTypeId}`).collection('reservations').doc(backendId);
  const availabilitySnapshot = yield call([ref, ref.get]);

  const data = availabilitySnapshot.data();

  debug('Day availability data', data);

  if (
    data &&
    data[selection.id] &&
    data[selection.id][selection.date] &&
    data[selection.id][selection.date].remaining === 0
  ) {
    throw new Error(
      'Sorry, we just double-checked and there are currently no more permits for the day you selected. Please press the "Reset Form" button and try again.'
    );
  }
}

function* fetchOvernightData(backendLocationId, backendId, reservationTypeId) {
  const ref = db.doc(`${backendLocationId}/${reservationTypeId}`).collection('reservations').doc(backendId);
  const availabilitySnapshot = yield call([ref, ref.get]);

  const data = availabilitySnapshot.data();

  return [[backendId], data];
}

export function* checkOvernightReservationAvailability(backendLocationId, reservationTypeId, selection, debug) {
  const prevQueriedMonths = yield select(queriedDatesSelector);

  const snapshotData = yield all(
    prevQueriedMonths.map((backendId) => call(fetchOvernightData, backendLocationId, backendId, reservationTypeId))
  );

  const queriedData = Object.fromEntries(snapshotData);
  debug('Overnight availability data', queriedData);

  Object.keys(selection).forEach((siteId) => {
    const { end, start } = selection[siteId];
    const { date, id } = start;

    const momentStart = Moment(date);
    const now = momentStart.clone();

    while (now.isBefore(end.date)) {
      const dateKey = now.format('YYYY-MM-DD');
      const queryMonth = Moment(dateKey).clone().format('MMMM-YYYY');

      const selectedMonthData = queriedData[queryMonth];

      if (
        selectedMonthData &&
        selectedMonthData[id] &&
        selectedMonthData[id][dateKey] &&
        selectedMonthData[id][dateKey].remaining === 0
      ) {
        throw new Error(
          'Sorry, we just double-checked and there are currently no more permits left for the days you selected. Please press the "Reset Form" button and try again.'
        );
      }

      now.add(1, 'days');
    }
  });
}
