import { createSelector } from 'reselect';

export const initAppErrorSelector = (state) => state.app.error;
export const isAppInitializingSelector = (state) => state.app.isLoading;

export const availableDayOptionsSelector = (state) => state.app.availableDayOptions;
export const availableOvernightOptionsSelector = (state) => state.app.availableOvernightOptions;
export const availableOvernightTrailheadsSelector = (state) => state.app.availableOvernightTrailheads;
export const customNoteSelector = (state) => state.app.customNote;
export const isFetchingSelector = (state) => state.app.isFetching;
export const isPortalEnabledSelector = (state) => state.app.isPortalEnabled;
export const mainStepsSelector = (state) => state.app.mainSteps;
export const portalSelector = (state) => state.app.portal;

export const isDayOptionsAvailableSelector = createSelector(
  availableDayOptionsSelector,
  (dayOptions) => !!dayOptions.length
);

export const isOvernightOptionsAvailableSelector = createSelector(
  availableOvernightOptionsSelector,
  (overnightOptions) => !!overnightOptions.length
);

export const isSanGorgonioEnabledSelector = createSelector(
  portalSelector,
  (portalData) => portalData && portalData.isSanGorgonioEnabled
);

export const isCucamongaEnabledSelector = createSelector(
  portalSelector,
  (portalData) => portalData && portalData.isCucamongaEnabled
);

export const isPortalUnderMaintenanceSelector = createSelector(
  portalSelector,
  (portalData) => portalData && portalData.isPortalMaintenance
);

export const startDateSelector = createSelector(portalSelector, (portalData) => portalData && portalData.startDate);
