import styled from 'styled-components';
import { media } from 'styles';

export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  color: #000000;
  margin: 0;
  text-align: center;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const SubTitle = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  color: #000000;
  margin: 0;
  text-align: center;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const EmailText = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  color: #2e5941;
  margin: 0;
  text-align: center;
  margin-bottom: 24px;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const Wrapper = styled.div`
  padding: 20px;
`;

export const ButtonWrapper = styled.div`
  float: right;
  margin-top: 20px;
`;

export const BottomWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #626262;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 12px 20px;

  ${media.twoDimMin.tablet`font-size: 1.8rem;`}
`;

export const MadeWithLove = styled.div`
  text-align: right;
`;

export const HyperLink = styled.a`
  outline: 0;
  background: transparent;
  border: 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  padding-bottom: 8px;
  color: #626262;

  &.focus-visible {
    border-bottom: 2px solid #4e8f6b;
  }

  &:hover {
    ${(p) => !p.disabled && `border-bottom: 2px solid #4e8f6b;`}
  }
`;
