import styled from 'styled-components';
import { media } from 'styles';

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #000000;
  margin: 0 0 4px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const SubTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 14px 0;

  ${media.twoDimMin.tablet`font-size: 1.8rem;`}
`;

export const Wrapper = styled.div`
  margin-bottom: 100px;
  padding: 20px;
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
`;

export const LoadingText = styled.h3`
  font-size: 2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;
