import styled from 'styled-components';
import { media, type } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  padding: 20%;
  background: #fff center;
`;

export const Text = styled.div`
  font-size: 1.6rem;
  font-weight: ${type.weight.semiBold};
  text-align: center;
  white-space: pre-wrap;

  ${media.min.baseline`font-size: 2rem;`}
`;

export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  color: #000;
  text-align: center;
  white-space: pre-wrap;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const StyledLink = styled.a`
  position: relative;
  display: inline-block;
  color: #2e5941;
  cursor: pointer;
  border: 0;
  outline: 0;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom: 2px solid #4e8f6b;
  }

  &.focus-visible {
    border-bottom: 2px solid #4e8f6b;
  }
`;
