import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import track, { TrackingPropType } from 'react-tracking';

import * as Fields from 'constants/reservationFields';

import TextField from './TextField';
import TextFieldNumber from './TextFieldNumber';
import MultiTextField from './MultiTextField';
import RulesConfirmation from './RulesConfirmation';
import {
  ArrowButton,
  ArrowButtonWrapper,
  BottomWrapper,
  ProgressBar,
  ProgressBarPercentage,
  ProgressBarWrapper,
  UserFormWrapper,
} from './style';

const userFormComponentMapping = {
  [Fields.MULTI_TEXT_FIELD]: MultiTextField,
  [Fields.RULES_CONFIRMATION]: RulesConfirmation,
  [Fields.TEXT_FIELD]: TextField,
  [Fields.TEXT_FIELD_NUMBER]: TextFieldNumber,
};

const ADDING_USER_CONFIRMATION = 1;

const UserForm = ({ fields, onAdvance, onChange, title, tracking: { trackEvent } }) => {
  const [furthestStepIndex, setFurthestStepIndex] = useState(0);
  const [index, setIndex] = useState(0);
  const [steps] = useState(fields);
  const [userFields, setUserFields] = useState({});

  const handleManualStepChange = (stepIndx) => {
    setIndex((stepIdx) => stepIdx - stepIndx);
  };

  const handleAdvance = () => {
    if (index <= steps.length - 1) {
      setIndex((indx) => {
        const currentIndex = indx + 1;
        trackEvent({ event: `Step #${currentIndex}` });

        return currentIndex;
      });
    }

    if (index === furthestStepIndex) {
      setFurthestStepIndex(furthestStepIndex + 1);
    }
  };

  const handleChange = (name, value) =>
    setUserFields({
      ...userFields,
      [name]: value,
    });

  const handleSubmit = (event) => {
    event.preventDefault();

    const userFieldKeys = Object.keys(userFields);
    const isEnd = userFieldKeys.length === fields.length;

    if (isEnd) {
      onChange('user', userFields);
      onAdvance();
    } else {
      handleAdvance();
    }
  };

  const currentStepField = steps[index];
  const { component, id, prompts, subTitle, terms, type } = currentStepField;
  const UserFormComponent = userFormComponentMapping[component];

  const isAdvanceable = index < furthestStepIndex;
  const isStart = index === 0;
  const isEnd = index + 1 === steps.length;

  const currentIndex = index + 1;
  const length = steps.length + ADDING_USER_CONFIRMATION;
  const percentLeft = Math.round((currentIndex / length) * 100);
  return (
    <>
      <TransitionGroup>
        <CSSTransition timeout={200} classNames="page" key={id}>
          <UserFormWrapper>
            <UserFormComponent
              id={id}
              key={id}
              onChange={handleChange}
              onSubmit={handleSubmit}
              type={type}
              value={userFields[id] || ''}
              title={title}
              subTitle={subTitle}
              prompts={prompts}
              terms={terms}
            />
          </UserFormWrapper>
        </CSSTransition>
      </TransitionGroup>
      <BottomWrapper>
        <ProgressBarWrapper>
          <ProgressBarPercentage>{percentLeft}% completed</ProgressBarPercentage>
          <ProgressBar width={percentLeft / 100} />
        </ProgressBarWrapper>
        <ArrowButtonWrapper>
          <ArrowButton disabled={isStart} onClick={() => handleManualStepChange(1)} />
          <ArrowButton disabled={!isAdvanceable || isEnd} isForward onClick={() => handleManualStepChange(-1)} />
        </ArrowButtonWrapper>
      </BottomWrapper>
    </>
  );
};

UserForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.any).isRequired,
  onAdvance: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tracking: TrackingPropType,
};

UserForm.defaultProps = {
  tracking: null,
};

const trackedUserForm = track({
  page: 'User Form',
})(UserForm);

export default trackedUserForm;
