import styled from 'styled-components';
import { media } from 'styles';

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: #000000;

  ${media.twoDimMin.tablet`
    font-size: 2.4rem;
  `}
`;

export const LocationStepWrapper = styled.div`
  width: 100%;
  padding: 20px;
`;

export const CancelButtonWrapper = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  margin: 20px;
`;

export const LoadingText = styled.h3`
  font-size: 2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const BottomWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #A6A6A6; 
  font-size: 1.6rem;
  font-weight: 600;
  padding: 20px;

  ${media.twoDimMin.tablet`font-size: 1.8rem;`}
`;

export const MadeWithLove = styled.div`
  text-align: right;
  color: #A6A6A6; 
  margin-top: 6px;
`;

export const HyperLink = styled.a`
  outline: 0;
  background: transparent;
  border: 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  padding-bottom: 4px;
  color: #A6A6A6;

  &.focus-visible {
    border-bottom: 2px solid #A6A6A6;
  }

  &:hover {
    ${(p) => !p.disabled && `border-bottom: 2px solid #A6A6A6;`}
  }
`;
