import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { connect } from 'react-redux';
import track from 'react-tracking';

import { dayAvailabilityTableSelector } from 'store/reservations/selectors';
import { startDateSelector } from 'store/app/selectors';

import Calendar from './Calendar';

const mapStateToProps = (state) => ({
  dayAvailability: dayAvailabilityTableSelector(state),
  forcedStartDate: startDateSelector(state),
});

export const DayCalendar = ({
  dayAvailability,
  fetchAvailability,
  forcedStartDate,
  isLoading,
  moreInfoText,
  onSubmit,
  reservationTypeId,
  selectedDate,
  subTitle,
  title,
}) => {
  const handleUserSelection = (userSelection, setUserSelection, selection) => {
    if (userSelection && selection.selectionId === userSelection.selectionId) {
      setUserSelection(null);
    } else setUserSelection(selection);
  };

  const handleSubmit = (selection) => {
    const queryDate = Moment(selection.date).toDate();
    const backendId = Moment(selection.date).format('MMMM-YYYY');

    const { date, id, remaining } = selection;

    const payload = { backendId, queryDate, selection: { date, id, remaining, title: selection.title } };
    onSubmit(payload);
  };

  const selectedStartedDate = Moment(`${forcedStartDate}`, 'MM-DD-YYYY');
  const today = Moment();
  const selectedStartDate = selectedStartedDate.isSameOrAfter(today) ? selectedStartedDate : today;

  const isSubmitButtonEnabled = (userSelection) => !!userSelection;
  return (
    <Calendar
      availability={dayAvailability}
      fetchAvailability={fetchAvailability}
      isLoading={isLoading}
      isSubmitButtonEnabled={isSubmitButtonEnabled}
      moreInfoText={moreInfoText}
      onSubmit={handleSubmit}
      onUserSelection={handleUserSelection}
      reservationTypeId={reservationTypeId}
      selectedDate={selectedDate}
      subTitle={subTitle}
      title={title}
      today={selectedStartDate}
    />
  );
};

const trackedDayCalendar = track({
  page: 'Day Calendar',
})(DayCalendar);

export default connect(mapStateToProps, null)(trackedDayCalendar);
