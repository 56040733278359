import { CUCAMONGA_WILDERNESS_ID, SAN_GORGONIO_WILDERNESS_ID } from './locationTypes';
import { DAY_ID, OVERNIGHT_ID } from './permitTypes';

const DAY_BACKEND_ID = 'day';
const OVERNIGHT_BACKEND_ID = 'overnight';

export const locationTypeBackendIds = {
  [CUCAMONGA_WILDERNESS_ID]: 'cucamonga',
  [SAN_GORGONIO_WILDERNESS_ID]: 'san_gorgonio',
};

export default {
  [DAY_ID]: DAY_BACKEND_ID,
  [OVERNIGHT_ID]: OVERNIGHT_BACKEND_ID,
};
