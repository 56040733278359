import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { heightSizes, widthSizes } from 'styles/media';
import track, { TrackingPropType } from 'react-tracking';

import { MessageHeader, NotSupportedMessage, WindowSizeObserverWrapper } from './style';

const MEDIA_QUERY_STRING = `(min-width: ${widthSizes.tablet / 16}em) and (min-height: ${
  heightSizes.tablet / 16
}em)`;

const WindowSizeObserver = ({ children, tracking: { trackEvent } }) => {
  const trackMessageShown = () => {
    trackEvent({
      event: 'Window Size Message Shown',
      screenHeight: window.screen && window.screen.height,
      screenWidth: window.screen && window.screen.width,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  };

  const handleMediaChange = (event) => {
    if (!event.matches) trackMessageShown();
    else trackEvent({ event: 'Window Size Observer Hidden' });
  };

  useEffect(() => {
    const mediaQueryList = matchMedia(MEDIA_QUERY_STRING);
    mediaQueryList.addListener(handleMediaChange);

    return () => mediaQueryList.removeListener(handleMediaChange);
  }, []);

  return (
    <WindowSizeObserverWrapper>
      {children}
      <NotSupportedMessage>
        <MessageHeader>
          Sorry, your current window size or screen resolution is either too small or not yet
          supported.
          <br />
          <br />
          Please try the following:
          <ul>
            <li>Expand your browser&apos;s window size</li>
            <li>Zoom out using the keyboard shortcut Ctrl (⌘ for Mac) and the – key</li>
          </ul>
        </MessageHeader>
      </NotSupportedMessage>
    </WindowSizeObserverWrapper>
  );
};

WindowSizeObserver.propTypes = {
  children: PropTypes.node.isRequired,
  tracking: TrackingPropType,
};

WindowSizeObserver.defaultProps = {
  tracking: null,
};

export default track({
  module: 'WindowSizeObserver',
})(WindowSizeObserver);
