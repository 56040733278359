const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = Buffer.from(b64Data, 'base64').toString('binary');
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export default (response, isIOSChrome) => {
  const blobUrl = b64toBlob(response, 'application/pdf');

  if (isIOSChrome) {
    // iOS Chrome requires using FileReader to properly download file
    const reader = new FileReader();
    reader.onload = () => {
      window.location.href = reader.result;
    };
    reader.readAsDataURL(blobUrl);
  } else {
    const url = (window.URL ? window.URL : window.webkitURL).createObjectURL(blobUrl);

    const downloadLink = document.createElement('a');
    downloadLink.style.display = 'none';

    const fileName = 'wilderness-permit.pdf';

    downloadLink.href = url;
    downloadLink.download = fileName;

    if (typeof downloadLink.download === 'undefined') {
      downloadLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(() => {
      document.body.removeChild(downloadLink);
      (window.URL ? window.URL : window.webkitURL).revokeObjectURL(url);
    });
  }
};
