import { createAction, createAsyncAction, createAsyncTypes } from 'lib/reduxUtils';

export const RESET_ERROR = 'cancel/resetError';
export const resetError = createAction(RESET_ERROR);

export const RESET_CANCEL = 'cancel/resetCancel';
export const resetCancel = createAction(RESET_CANCEL);

export const FETCH_USER_RESERVATION = createAsyncTypes('cancel/fetchUserReservation');
export const fetchUserReservation = createAsyncAction(FETCH_USER_RESERVATION);
