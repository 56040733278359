import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { GENERATE_PERMIT, generatePermit } from '../actions';
import { functions } from '../../../firebase';

const debug = Debug('sgwa:permit-portal:store:reservations:sagas:generatePermit');

export function* generatePermitSaga({ payload }) {
  debug('Saga called', payload);

  try {
    const generatePermitFunction = functions.httpsCallable('generatePermit');
    const response = yield call(generatePermitFunction, payload);

    const pdf = response.data.pdfBase64;
    debug('PDF successful made');

    yield put(generatePermit.success(pdf));
  } catch (e) {
    debug('Saga error', e);
    yield put(generatePermit.error(e));
  }
}

export default function* () {
  yield takeLatest(GENERATE_PERMIT.PENDING, generatePermitSaga);
}
