import { FETCH_PORTAL_COLLECTION } from 'store/app/actions';
import { FETCH_USER_RESERVATION, RESET_ERROR, RESET_CANCEL } from 'store/cancel/actions';
import { CANCEL_RESERVATION } from 'store/reservations/actions';

export const INITIAL_STATE = {
  didCancel: false,
  error: null,
  isLoading: false,
  steps: null,
  userReservation: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case RESET_CANCEL:
      return {
        ...state,
        didCancel: false,
        error: null,
        userReservation: null,
      };
    case FETCH_PORTAL_COLLECTION.SUCCESS:
      return {
        ...state,
        steps: payload.steps.cancel.steps,
      };
    case CANCEL_RESERVATION.PENDING:
    case FETCH_USER_RESERVATION.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case CANCEL_RESERVATION.ERROR:
    case FETCH_USER_RESERVATION.ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case FETCH_USER_RESERVATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        userReservation: payload,
      };
    case CANCEL_RESERVATION.SUCCESS:
      return {
        ...state,
        didCancel: true,
        isLoading: false,
      };
    default:
      return state;
  }
};
