import { createSelector } from 'reselect';
import Moment from 'moment';

import { locationTypeBackendIds } from 'constants/backendIds';

import {
  availableDayOptionsSelector,
  availableOvernightOptionsSelector,
  availableOvernightTrailheadsSelector,
} from 'store/app/selectors';

export const availabilitySelector = (state) => state.reservations.availability;
export const isLoadingReservationsSelector = (state) => state.reservations.isLoading;
export const optionsSelector = (state) => state.reservations.options;
export const permitPDFSelector = (state) => state.reservations.permitPDF;
export const queriedDatesSelector = (state) => state.reservations.queriedDates;
export const reservationSelector = (state) => state.reservations.reservation;
export const reservationsErrorSelector = (state) => state.reservations.error;
export const reservationTypeSelector = (state) => state.reservations.reservationType;
export const loadingMessageSelector = (state) => state.reservations.loadingMessage;
export const locationTypeSelector = (state) => state.reservations.locationType;
export const didFetchMainCollectionSelector = (state) => state.reservations.didFetchMainCollection;

export const isReservationCompleteSelector = createSelector(reservationSelector, (reservation) => !!reservation);

export const locationTypeBackendIdSelector = createSelector(
  locationTypeSelector,
  (locationType) => locationType && locationTypeBackendIds[locationType]
);

export const trailheadsOptionSelector = createSelector(
  optionsSelector,
  (options) => options && options.trailheadOptions
);

export const reservationTypeOptionsSelector = createSelector(optionsSelector, (options) => options && options.options);

const availabilityDateModifiedSelector = createSelector(
  availabilitySelector,
  (availability) =>
    availability &&
    Object.keys(availability).reduce((acc, siteKey) => {
      const siteDates = availability[siteKey];
      Object.keys(siteDates).forEach((dateId) => {
        const formatDate = Moment(dateId).format('YYYY-MM-DD');

        acc[siteKey] = {
          ...acc[siteKey],
          [formatDate]: siteDates[dateId],
        };
      });

      return acc;
    }, {})
);

export const dayAvailabilityTableSelector = createSelector(
  availableDayOptionsSelector,
  availabilityDateModifiedSelector,
  reservationTypeOptionsSelector,
  (availableOptions, availability, options) =>
    availableOptions
      .map((optionId) => ({
        dates: availability && availability[optionId],
        id: options[optionId].id,
        title: options[optionId].title,
        total: options[optionId].total,
      }))
      .filter((d) => !!d)
);

export const overnightAvailabilityTableSelector = createSelector(
  availableOvernightOptionsSelector,
  availabilityDateModifiedSelector,
  reservationTypeOptionsSelector,
  (availableOptions, availability, options) =>
    availableOptions
      .map((optionId) => ({
        areaTitle: options[optionId].areaTitle,
        dates: availability && availability[optionId],
        id: options[optionId].id,
        title: options[optionId].title,
        total: options[optionId].total,
      }))
      .filter((d) => !!d)
);

export const trailheadsAvailabilitySelector = createSelector(
  availableOvernightTrailheadsSelector,
  trailheadsOptionSelector,
  (availableOptions, options) =>
    availableOptions
      .map((optionId) => ({
        id: options[optionId].id,
        title: options[optionId].title,
      }))
      .filter((d) => !!d)
);
