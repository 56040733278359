import { FETCH_PORTAL_COLLECTION, FETCH_MAIN_COLLECTION, INITIALIZE_APP } from './actions';

export const INITIAL_STATE = {
  availableDayOptions: [],
  availableOvernightOptions: [],
  availableOvernightTrailheads: [],
  customNote: null,
  error: null,
  isFetching: false,
  isLoading: false,
  mainSteps: null,
  portal: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case INITIALIZE_APP.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case INITIALIZE_APP.ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case INITIALIZE_APP.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case FETCH_PORTAL_COLLECTION.SUCCESS:
      return {
        ...state,
        mainSteps: { locationStepId: payload.steps.locationStepId },
        portal: payload,
      };
    case FETCH_MAIN_COLLECTION.PENDING:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_MAIN_COLLECTION.ERROR:
      return {
        ...state,
        error: payload,
        isFetching: false,
      };
    case FETCH_MAIN_COLLECTION.SUCCESS: {
      const {
        availableDayOptions,
        availableOvernightOptions,
        availableOvernightTrailheads,
        customNote,
      } = payload.start;
      return {
        ...state,
        availableDayOptions,
        availableOvernightOptions,
        availableOvernightTrailheads,
        customNote,
        isFetching: false,
        mainSteps: { ...state.mainSteps, ...payload.steps },
      };
    }
    default:
      return state;
  }
};
