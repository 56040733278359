/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Moment from 'moment';

import { generateCalendarDateLabel } from 'lib/utils';

import { FieldPlaceholder } from 'styles/components';
import { OvernightCell } from './style';

const TabelCell = ({ ariaLabel, cellText, isBetweenDates, isDisabled, isEnd, isStart, isUnreservable, onClick }) => (
  <OvernightCell 
    aria-label={ariaLabel} 
    isBetweenDates={isBetweenDates} 
    isDisabled={isDisabled || isUnreservable}
    isEnd={isEnd} 
    isStart={isStart}
    onClick={onClick} 
  >
    {cellText}
  </OvernightCell>
);


const renderTableCells = (date, { isBetweenDates, isDisabled, isEnd, isStart }, onUserSelection, site, siteData, siteSelectionId) => {
  const cellText = generateCalendarDateLabel(siteData ? siteData.remaining : site.total);
  const ariaLabel = `${date.id}, People: ${cellText} out of ${site.total}`;
  const isUnreservable = siteData && siteData.remaining <= 0;
  const data = {
    date: date.id,
    id: site.id,
    selectionId: siteSelectionId,
    title: site.title,
    total: site.total,
  };

  const onClick = () => {
    if (isUnreservable || isDisabled) return null;
    return onUserSelection({ ...data, remaining: siteData ? siteData.remaining : site.total }, site.dates);
  };

  return (
    <TabelCell
      ariaLabel={ariaLabel}
      cellText={cellText}
      isBetweenDates={isBetweenDates}
      isDisabled={isDisabled}
      isEnd={isEnd}
      isStart={isStart}
      isUnreservable={isUnreservable}
      key={siteSelectionId}
      onClick={onClick}
    />
  );
};

const OvernightTableCell = ({ furthestStartDate, headerItems, isLoading, onUserSelection, selection, site }) => {
  if (isLoading) {
    return headerItems.map((date) => (
      <OvernightCell key={`${date.id}-${site.id}`} aria-label="Loading Dates">
        <FieldPlaceholder width="40px" />
      </OvernightCell>
    ));
  }

  return headerItems.map((date) => {
    const siteSelectionId = `${date.id}-${site.id}`;
    const siteData = site.dates && site.dates[date.id];
    const selectionData = selection && selection[site.id];

    let isDisabled = furthestStartDate && furthestStartDate.siteId !== site.id && !Moment(furthestStartDate.date).isBefore(date.id);
    let isStart = false;
    let isEnd = false;
    let isBetweenDates = false;

    if (selectionData) {
      Object.keys(selectionData).forEach((dateKey) => {
        if (dateKey === 'start') {
          isStart = selectionData[dateKey].selectionId === siteSelectionId;
          isDisabled = !Moment(selectionData[dateKey].date).isSameOrBefore(date.id);
        }

        if (dateKey === 'end') {
          isEnd = selectionData[dateKey].selectionId === siteSelectionId;
        }

        const startDate = Moment(selectionData.start?.date);
        const endDate = Moment(selectionData.end?.date);
        const currentDate = Moment(date.id);  

        isBetweenDates = currentDate.isAfter(startDate) && currentDate.isBefore(endDate);
      });
    }

    return renderTableCells(date, { isBetweenDates, isDisabled, isEnd, isStart }, onUserSelection, site, siteData, siteSelectionId);
  });
};

export default OvernightTableCell;