import { fork, all } from 'redux-saga/effects';

import loggerSaga from 'lib/loggerSaga';
import appSagas from './app/sagas';
import reservationSagas from './reservations/sagas';
import cancelSagas from './cancel/sagas';

const sagas = [loggerSaga, ...appSagas, ...reservationSagas, ...cancelSagas];

export default function* rootSaga() {
  yield all(sagas.map((saga) => fork(saga)));
}
