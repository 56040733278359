import styled from 'styled-components';
import { media } from 'styles';


export const Button = styled.button`
  font-size: 1.6rem;
  background: #2E5941;
  color: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 3px;
  padding: 14px;
  text-align: left;
  width: 300px;

  ${media.twoDimMin.tablet`font-size: 1.8rem;`}
  
  &:hover { background: #4E8F6B; }
  &:last-child { margin-top: 8px; }
  ${p => (p.disabled && 'opacity: 0.5;')}
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};

  &.focus-visible {
    background-color: #4E8F6B;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;  
  align-content: flex-start; 
`;
