import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { locationTypeBackendIds } from 'constants/backendIds';

import { db } from '../../../firebase';
import { FETCH_MAIN_COLLECTION, fetchMainCollection } from '../actions';

const debug = Debug('sgwa:permit-portal:store:app:sagas:fetchMainCollection');

export function* fetchMainCollectionSaga({ payload: locationTypeId }) {
  debug('Saga called', locationTypeId);

  const backendLocationId = locationTypeBackendIds[locationTypeId];
  try {
    const ref = db.collection(backendLocationId).doc('main');
    const data = yield call([ref, ref.get]);

    const mainCollection = data.data();
    debug(`Main collection for ${backendLocationId} fetched`, mainCollection);

    yield put(fetchMainCollection.success(mainCollection));
  } catch (e) {
    debug('Saga error', e.message);
    yield put(fetchMainCollection.error(e.message));
  }
}

export default function* () {
  yield takeLatest(FETCH_MAIN_COLLECTION.PENDING, fetchMainCollectionSaga);
}
