import styled, { keyframes } from 'styled-components';
import { type } from 'styles';

export const DeviceOrientationObserverWrapper = styled.div`
  position: relative;

  @media (orientation: portrait) {
    height: 100vh;
    overflow: hidden;
  }
`;

export const NotSupportedMessage = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10%;
  background: #2e5941 center;
  font-size: 1.6rem;

  color: #fff;
  text-align: center;
  opacity: 1;

  & span {
    margin-top: 44px;
    font-weight: 700;
  }

  & p {
    margin-top: 22px;
  }

  @media (orientation: landscape) {
    visibility: hidden;
    opacity: 0;
  }
`;

const rotateRight = keyframes`
  60%, 100% { transform: rotate(90deg); }
`;

export const PhoneIcon = styled.div`
  background: linear-gradient(to left, currentColor 10px, transparent 0) no-repeat 28px 20px/10px
    20px;
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 80px;
  height: 128px;
  border: 8px solid;
  border-radius: 8px;

  animation: ${rotateRight} 2s ease infinite;
`;
