import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as PermitTypes from 'constants/permitTypes';

import Moment from 'moment';
import Calendar from './Calendar';

export const CalendarContainer = ({
  availability,
  fetchAvailability,
  isLoading,
  isSubmitButtonEnabled,
  moreInfoText,
  onSubmit,
  onUserSelection,
  reservationTypeId,
  selectedDate,
  subTitle,
  title,
  today,
}) => {
  const refreshTimeoutRef = useRef(null);

  const [dateController, setDateController] = useState(Moment(selectedDate));
  const [headerItems, setHeaderItems] = useState([]);
  const [userSelection, setUserSelection] = useState(null);
  const [furthestStartDate, setFurthestStartDate] = useState(null);

  const isDateBeforeToday = dateController.clone().subtract(1, 'days').isSameOrBefore(today);
  const isDateAfterMax = dateController.isSameOrAfter(Moment().add(90, 'days'));

  useEffect(() => {
    const currentMonth = dateController.clone().format('MMMM');
    const currentAddDay = dateController.clone();
    const currentSubtractDay = dateController.clone();

    for (let day = 0; day < 6; day += 1) {
      if (currentAddDay.format('MMMM') !== currentMonth) {
        fetchAvailability({
          selectedDate: currentAddDay.clone(),
          typeId: reservationTypeId,
        });

        break;
      }

      currentAddDay.add(1, 'day');
    }

    for (let day = 0; day < 6; day += 1) {
      if (currentSubtractDay.format('MMMM') !== currentMonth) {
        fetchAvailability({
          selectedDate: currentSubtractDay.clone(),
          typeId: reservationTypeId,
        });

        break;
      }

      currentSubtractDay.subtract(1, 'day');
    }

    refreshTimeoutRef.current = setInterval(() => {
      fetchAvailability({
        allowPolling: true,
        selectedDate: dateController.clone(),
        typeId: reservationTypeId,
      });
    }, 1000 * 60);

    return () => refreshTimeoutRef.current && clearInterval(refreshTimeoutRef.current);
  }, []);

  useEffect(() => {
    if (reservationTypeId === PermitTypes.DAY_ID) return;

    if (userSelection && Object.keys(userSelection).length) {
      Object.keys(userSelection).forEach((siteId) => {
        const {
          start: { date },
        } = userSelection[siteId];

        if (!furthestStartDate) setFurthestStartDate({ date, siteId });
        else if (!furthestStartDate[siteId]) setFurthestStartDate({ date, siteId });
        else if (Moment(date).isAfter(furthestStartDate.date)) setFurthestStartDate({ date, siteId });
      });
    } else if (furthestStartDate) setFurthestStartDate(null);
  }, [userSelection]);

  useEffect(() => {
    setHeaderItems([]);
    const start = dateController.clone();

    for (let day = 0; day < 5; day += 1) {
      const date = start.format('Do');
      const dateId = start.format('YYYY-MM-DD');

      setHeaderItems((items) => [...items, { date, id: dateId }]);
      start.add(1, 'day');
    }
  }, [dateController]);

  const handleDateChange = (direction) => {
    if (direction === 1 && !isDateAfterMax) {
      setDateController(dateController.clone().add(1, 'days'));

      const endOfMonth = dateController.clone().endOf('month');
      const fiveDaysFromToday = dateController.clone().add(5, 'days');

      if (endOfMonth.format('YYYY-DD-MM') === fiveDaysFromToday.format('YYYY-DD-MM')) {
        const selectDayAfterFiveDaysFromToday = dateController.clone().add(6, 'days');

        fetchAvailability({
          selectedDate: selectDayAfterFiveDaysFromToday,
          typeId: reservationTypeId,
        });
      }
    } else if (direction === -1 && !isDateBeforeToday) {
      setDateController(dateController.clone().subtract(1, 'days'));

      const beginningOfMonth = dateController.clone().startOf('month');
      const yesterday = dateController.clone().subtract(1, 'days');

      if (beginningOfMonth.format('YYYY-DD-MM') === yesterday.format('YYYY-DD-MM')) {
        const selectDayPriorToYesterday = dateController.clone().subtract(2, 'days');

        fetchAvailability({
          selectedDate: selectDayPriorToYesterday,
          typeId: reservationTypeId,
        });
      }
    }
  };

  const handleUserSelection = (selection, siteDates) => {
    onUserSelection(userSelection, setUserSelection, selection, siteDates);
  };

  const handleSubmit = () => {
    const selection = { ...userSelection };
    onSubmit(selection);
  };

  const isSubmitEnabled = isSubmitButtonEnabled(userSelection);
  return (
    <Calendar
      availability={availability}
      calendarTitle={dateController.clone().format('MMMM YYYY').toUpperCase()}
      furthestStartDate={furthestStartDate}
      headerItems={headerItems}
      isDateAfterMax={isDateAfterMax}
      isDateBeforeToday={isDateBeforeToday}
      isLoading={isLoading}
      isSubmitEnabled={isSubmitEnabled}
      moreInfoText={moreInfoText}
      onDateChange={handleDateChange}
      onResetButtonClick={() => setUserSelection(null)}
      onSubmit={handleSubmit}
      onUserSelection={handleUserSelection}
      reservationTypeId={reservationTypeId}
      subTitle={subTitle}
      title={title}
      userSelection={userSelection}
    />
  );
};

export default CalendarContainer;
