import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { db } from '../../../firebase';

import { FETCH_USER_RESERVATION, fetchUserReservation } from '../actions';

const debug = Debug('sgwa:permit-portal:store:cancel:sagas:fetchUserReservation');

export function* fetchUserReservationSaga({ payload: permitNumber }) {
  debug('Saga called', permitNumber);

  try {
    let reservationData;

    const sanGRef = db.doc('san_gorgonio/main').collection('reservations').doc(permitNumber);
    const sanGReservation = yield call([sanGRef, sanGRef.get]);

    const sanGData = sanGReservation.data();
    reservationData = sanGData;

    if (!reservationData) {
      debug('No reservation found for San Gorgonio, checking Cucamonga');

      const cucaRef = db.doc('cucamonga/main').collection('reservations').doc(permitNumber);
      const cucaReservation = yield call([cucaRef, cucaRef.get]);

      const cucaData = cucaReservation.data();
      reservationData = cucaData;
    }

    if (!reservationData) throw Error('No reservation found');

    const { isCancelled } = reservationData;
    if (isCancelled) throw Error('This reservation was already cancelled');

    debug('Reservation found for document', reservationData);

    yield put(fetchUserReservation.success(reservationData));
  } catch (e) {
    debug('Saga error', e.message);
    yield put(fetchUserReservation.error(e.message));
  }
}

export default function* () {
  yield takeLatest(FETCH_USER_RESERVATION.PENDING, fetchUserReservationSaga);
}
