import styled from 'styled-components';
import { media } from 'styles';

export const Title = styled.h1`
  color: #000000;
  font-weight: 500;
  font-size: 2.2rem;
  margin: 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const TitleWrapper = styled.div`
  align-self: flex-start;
  margin-bottom: 30px;

  & p {
    max-width: 55rem;
  }
`;

export const MonthYearTextWrapper = styled.h3`
  align-self: flex-start;
  color: #626262;
  font-weight: 500;
  text-align: right;
`;

export const SubTitle = styled.h3`
  font-size: 1.8rem;
  color: #626262;
  font-weight: 400;
  margin: 0;

  ${media.twoDimMin.tablet`font-size: 2rem;`}
`;

export const MoreInfoText = styled.h4`
  max-width: 60rem;
  align-self: flex-start;
  color: #626262;
  font-size: 1.4rem;
  font-weight: 400;
`;

export const CalendarWrapper = styled.div`
  padding: 40px 20px;
`;

export const Table = styled.div`
  position: relative;
  width: 100%;
  min-width: 68rem;
`;

export const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 3;
  padding: 12px 0;
  margin-bottom: 12px;
`;

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 12px;

  ${(p) =>
    p.isDisabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

export const HeaderCell = styled.div`
  width: 15%;
  color: #626262;
  text-align: center;
  font-size: 1.8rem;

  ${media.twoDimMin.tablet`font-size: 2rem;`}

  &:first-child {
    width: 52%;
    text-align: left;
    font-size: 2rem;
    font-weight: 500;

    ${media.twoDimMin.tablet`font-size: 2.2rem;`}
  }
`;

export const CellTitle = styled.span`
  display: block;
  width: 52%;
  color: #000;
  font-weight: 500;
  font-size: 1.6rem;
  text-align: left;

  & p {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 400;
    color: #626262;
  }

  ${media.twoDimMin.tablet`font-size: 1.8rem;`}

  ${(p) => p.isOpen && 'border-bottom: 1px solid #000000;'}
`;

export const LeftButtonWrapper = styled.button`
  position: absolute;
  left: 38%;
  top: 18%;
  opacity: 1;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 4px;
  border: 1px solid transparent;
  cursor: pointer;

  &.focus-visible {
    box-shadow: #4e8f6b 0px 0px 0px 2px;
    border: 1px solid #4e8f6b;
  }

  ${(p) =>
    p.isDisabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

export const RightButtonWrapper = styled.button`
  position: absolute;
  right: -2%;
  top: 18%;
  opacity: 1;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 4px;
  cursor: pointer;
  border: 1px solid transparent;

  &.focus-visible {
    box-shadow: #4e8f6b 0px 0px 0px 2px;
    border: 1px solid #4e8f6b;
  }

  ${(p) =>
    p.isDisabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}
`;

export const ArrowIcon = styled.div`
  height: 0;
  width: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  ${(p) => (p.isRight ? 'border-left: 10px solid #626262;' : 'border-right: 10px solid #626262;')}
`;

export const FooterWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;
