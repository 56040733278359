import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

import Config from 'config'; // eslint-disable-line

firebase.initializeApp(Config.firebaseConfig);

if (Config.environment === 'dev') window.firebase = firebase;

export const auth = firebase.auth();
export const functions = firebase.functions();
export const firebaseInstance = firebase;
export const db = firebase.firestore();

// This feature caches a copy of the Cloud Firestore data that your app is actively using, so your app can access the data when the device is offline. You can write, read, listen to, and query the cached data. When the device comes back online, Cloud Firestore synchronizes any local changes made by your app to the Cloud Firestore backend.
db.enablePersistence().catch((err) => {
  if (err.code === 'failed-precondition') {
    console.error('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
  } else if (err.code === 'unimplemented') {
    console.error('The current browser does not support all of the features required to enable persistence');
  }
});

const appCheck = firebase.appCheck();
appCheck.activate(
  new firebase.appCheck.ReCaptchaEnterpriseProvider(Config.reCAPTCHASiteKey),
  true
);

export default firebase;
