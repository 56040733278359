import styled from 'styled-components';
import { media } from 'styles';

export const Wrapper = styled.div`
  align-self: flex-start;
  width: 100%;
  padding: 60px 20px;
`;

export const SubTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 300;
  color: #626262;
  margin: 0 0 30px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  color: #000000;
  margin: 0 0 6px 0;

  ${media.twoDimMin.tablet`font-size: 2.4rem;`}
`;

// TextField

export const TextFieldWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid #2e5941;
  width: 100%;

  ${media.min.mobileLandscape`width: 500px;`}
`;

export const TextFieldInput = styled.input`
  width: 100%;
  background-color: transparent;
  border: 0;
  color: #2e5941;
  outline: 0;
  box-shadow: none;
  font-size: 2.4rem;
  font-weight: 500;
  padding: 12px 12px 12px 0;
  font-size: 2.2rem;
  ${media.twoDimMin.tablet`font-size: 2.4rem;`}

  &::placeholder {
    color: #d0d0d0;
    font-weight: 400;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

export const Checkbox = styled.input`
  background-color: #2e5941;
  border: 1px solid #000000;
  border-radius: 3px;
  width: 22px;
  height: 22px;
`;

export const CheckboxLabel = styled.label`
  max-width: 500px;
  width: 100%;
  margin-left: 16px;
  color: #000000;
  font-weight: 500;
  font-size: 1.4rem;

  ${media.twoDimMin.tablet`font-size: 1.6rem;`}
`;

// Loading

export const LoadingWrapper = styled.div`
  max-width: 600px;
  text-align: center;
`;

// Progress Bar

export const BottomWrapper = styled.div`
  position: fixed;
  display: block;
  bottom: 0;
  margin-bottom: 28px;

  ${media.min.mobileLandscape`display: none;`}
  ${media.twoDimMin.tablet`display: block;`}
`;

export const ProgressBarPercentage = styled.span`
  font-size: 1.4rem;
  line-height: 16px;

  ${media.twoDimMin.tablet`font-size: 1.6rem;`}
`;

export const ProgressBar = styled.div`
  position: relative;
  height: 6px;
  display: block;
  width: 260px;
  background-color: #efefef;
  border-radius: 2px;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    background-color: #2e5941;
    top: 0;
    left: 0;
    bottom: 0;
    width: 260px;
  }
`;
