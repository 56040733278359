import React from 'react';
import PropTypes from 'prop-types';

import { generateCalendarDateLabel } from 'lib/utils';

import { FieldPlaceholder } from 'styles/components';
import { Cell } from './style';

const DayTableCell = ({ headerItems, isLoading, onUserSelection, reservationTypeId, selection, site }) => (
  <>
    {headerItems.map((date, index) => {
      if (isLoading) {
        return (
          <Cell key={`${date.id}-${site.id}`} aria-label="Loading Dates">
            <FieldPlaceholder width="40px" />
          </Cell>
        );
      }

      const isSelected = selection && selection.selectionId === `${date.id}-${site.id}`;
      const siteData = site.dates && site.dates[date.id];
      if (siteData) {
        const remaining = generateCalendarDateLabel(siteData.remaining);
        const isDisabled = siteData.remaining <= 0;
        const onClick = !isDisabled
          ? () =>
              onUserSelection({
                date: date.id,
                id: site.id,
                remaining: siteData.remaining,
                selectionId: `${date.id}-${site.id}`,
                title: site.title,
              })
          : undefined;
        return (
          <Cell
            key={`${date.id}-${site.id}-${index}`}
            aria-label={`${date.id}, People: ${remaining} out of ${site.total}`}
            onClick={onClick}
            isDisabled={isDisabled}
            isSelected={isSelected}
          >
            {remaining}
          </Cell>
        );
      }

      const totalRemaining = generateCalendarDateLabel(site.total);
      return (
        <Cell
          key={`${date.id}-${site.id}-${index}`}
          aria-label={`${date.id}, People: ${site.total} out of ${site.total}`}
          isSelected={isSelected}
          onClick={() =>
            onUserSelection({
              date: date.id,
              id: site.id,
              remaining: site.total,
              selectionId: `${date.id}-${site.id}`,
              title: site.title,
            })
          }
        >
          {totalRemaining}
        </Cell>
      );
    })}
  </>
);

DayTableCell.propTypes = {};

DayTableCell.defaultProps = {};

export default DayTableCell;
