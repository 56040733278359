import { combineReducers } from 'redux';

import app from './app/reducer';
import cancel from './cancel/reducer';
import userAgent from './userAgent/reducer';
import reservations from './reservations/reducer';

export default combineReducers({
  app,
  cancel,
  reservations,
  userAgent,
});
