/* eslint-disable sort-keys */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';

import { toast } from 'react-toastify';
import * as PermitTypes from 'constants/permitTypes';

import { isDayOptionsAvailableSelector, isOvernightOptionsAvailableSelector } from 'store/app/selectors';

import MultipleChoice from 'components/shared/MultipleChoice';
import { IntroStepWrapper, Title } from './style';

const mapStateToProps = (state) => ({
  isDayOptionsAvailable: isDayOptionsAvailableSelector(state),
  isOvernightOptionsAvailable: isOvernightOptionsAvailableSelector(state),
});

const StartStep = ({
  isDayOptionsAvailable,
  isOvernightOptionsAvailable,
  onChange,
  options,
  title,
  tracking: { trackEvent },
}) => {
  const handleChange = (optionIndex) => {
    const { id, steps } = options[optionIndex];

    if (id === PermitTypes.DAY_ID && !isDayOptionsAvailable) {
      toast.error('Sorry, there are currently no day permits available for booking.');
    } else if (id === PermitTypes.OVERNIGHT_ID && !isOvernightOptionsAvailable) {
      toast.error('Sorry, there are currently no overnight permits available for booking.');
    } else {
      onChange('reservationType', { id, steps });

      trackEvent({ event: 'Option Selected', id });
    }
  };

  return (
    <IntroStepWrapper>
      <Title>{title}</Title>
      <MultipleChoice onClick={handleChange} options={options} />
    </IntroStepWrapper>
  );
};

StartStep.propTypes = {
  isDayOptionsAvailable: PropTypes.bool.isRequired,
  isOvernightOptionsAvailable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  tracking: TrackingPropType,
};

StartStep.defaultProps = {
  tracking: null,
};

const trackedStartStep = track({})(StartStep);

export default connect(mapStateToProps, null)(trackedStartStep);
