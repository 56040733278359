import React from 'react';
import PropTypes from 'prop-types';

import { DeviceOrientationObserverWrapper, NotSupportedMessage, PhoneIcon } from './style';

export const DeviceOrientationObserver = ({ children, isLandscapeMode }) =>
  isLandscapeMode ? (
    <DeviceOrientationObserverWrapper>
      {children}
      <NotSupportedMessage>
        <PhoneIcon />
        <span>
          Please rotate your device to landscape (horizontal) mode to access this section of the
          permit process.
        </span>
        <p>Note: Make sure your Portrait Orientation Lock button is off.</p>
      </NotSupportedMessage>
    </DeviceOrientationObserverWrapper>
  ) : (
    children
  );

DeviceOrientationObserver.propTypes = {
  children: PropTypes.node.isRequired,
  isLandscapeMode: PropTypes.bool.isRequired,
};

export default DeviceOrientationObserver;
