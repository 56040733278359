import Debug from 'debug';
import { call, select, put, takeLatest } from 'redux-saga/effects';
import Moment from 'moment';

import backendIds from 'constants/backendIds';
import awaitAsyncAction from 'lib/awaitAsyncAction';

import { FETCH_AVAILABILITY, FETCH_RESERVATION_OPTIONS, fetchAvailability, fetchReservationOptions } from '../actions';
import { queriedDatesSelector, locationTypeBackendIdSelector } from '../selectors';
import { db } from '../../../firebase';

const debug = Debug('sgwa:permit-portal:store:reservations:sagas:fetchAvailability');

export function* fetchAvailabilitySaga({ payload }) {
  const { allowPolling, selectedDate, typeId } = payload;
  debug('Saga called', payload);

  let queriedDateId;
  if (Moment.isMoment(selectedDate)) {
    queriedDateId = `${selectedDate.format('MMMM')}-${selectedDate.format('YYYY')}`;
  } else {
    const selectedDateMoment = Moment(selectedDate);
    queriedDateId = `${selectedDateMoment.format('MMMM')}-${selectedDateMoment.format('YYYY')}`;
  }

  const reservationTypeId = backendIds[typeId];
  const previousQueriedDates = yield select(queriedDatesSelector);
  const backendLocationId = yield select(locationTypeBackendIdSelector);

  if (!previousQueriedDates.length) {
    yield put(fetchReservationOptions(reservationTypeId));
    yield call(awaitAsyncAction, FETCH_RESERVATION_OPTIONS);
  }

  if (!allowPolling && previousQueriedDates.includes(queriedDateId)) {
    debug('Already queried date', queriedDateId);
    yield put(fetchAvailability.success());
  } else {
    try {
      const reservationAvailabilityRef = db
        .doc(`${backendLocationId}/${reservationTypeId}`)
        .collection('reservations')
        .doc(queriedDateId);

      const reservationAvailability = yield call([reservationAvailabilityRef, reservationAvailabilityRef.get]);

      const data = reservationAvailability.data() || {};
      debug('Availability data', data);

      yield put(
        fetchAvailability.success({
          availabilityData: data,
          queriedDateId,
          reservationTypeId: typeId,
        })
      );
    } catch (e) {
      debug('Saga error', e.message);
      yield put(fetchAvailability.error(e.message));
    }
  }
}

export default function* () {
  yield takeLatest(FETCH_AVAILABILITY.PENDING, fetchAvailabilitySaga);
}
