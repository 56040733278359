/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

import * as PermitTypes from 'constants/permitTypes';
import { locationTypeFromBackendTitles } from 'constants/locationTypes';

import { Body, Header, PermitTitle, SubText, SummaryWrapper, Text, TypeTitle } from './style';

const reservationMappings = {
  [PermitTypes.DAY_ID]: 'Day',
  [PermitTypes.OVERNIGHT_ID]: 'Overnight',
};

export const DocumentSummary = ({ reservation }) => {
  const { locationType, permitNumber, reservationType, selection } = reservation;
  const locationTitle = `${locationTypeFromBackendTitles[locationType]}`;

  let firstName;
  let lastName;

  if (reservation.user) {
    ({ firstName } = reservation.user);
    ({ lastName } = reservation.user);
  } else {
    // Old reservation object prior June 2021
    ({ firstName } = reservation);
    ({ lastName } = reservation);
  }

  const reservationTypeTitle = reservationMappings[reservationType];
  let site;

  if (reservation.user) {
    if (reservationType === PermitTypes.DAY_ID) {
      site = (
        <>
          <Text>{`At ${selection.title}`}</Text>
          <SubText>{`${selection.date}`}</SubText>
        </>
      );
    } else {
      site = Object.keys(selection).map((siteId, index) => {
        const { end, start } = selection[siteId];

        return (
          <React.Fragment key={`${start.id}_${index}`}>
            <Text>{`At ${start.title}`}</Text>
            <SubText>{`${start.date} to ${end.date}`}</SubText>
          </React.Fragment>
        );
      });
    }
  }

  // Old reservation object prior June 2021
  if (reservation.date) {
    const { date, trail } = reservation;

    if (reservationType === PermitTypes.DAY_ID) {
      site = (
        <>
          <Text>{`At ${trail.title}`}</Text>
          <SubText>{`${date.formatDate}`}</SubText>
        </>
      );
    } else {
      site = Object.keys(date).map((trailId, index) => {
        const selectedTrail = date[trailId];

        const start = Moment(selectedTrail.dates.start).local().format('LL');
        const end = Moment(selectedTrail.dates.end).local().format('LL');
        return (
          <React.Fragment key={`${selectedTrail.trail.id}_${index}`}>
            <Text>{`At ${selectedTrail.trail.title}`}</Text>
            <SubText>{`${start} to ${end}`}</SubText>
          </React.Fragment>
        );
      });
    }
  }

  return (
    <SummaryWrapper>
      <Header>
        <TypeTitle>
          {locationTitle} {reservationTypeTitle} Permit
        </TypeTitle>
        <PermitTitle>#{permitNumber}</PermitTitle>
      </Header>
      <Body>
        <Text>{`${firstName} ${lastName}`}</Text>
        {site}
      </Body>
    </SummaryWrapper>
  );
};

DocumentSummary.propTypes = {
  reservation: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DocumentSummary;
