/* eslint-disable react/jsx-indent */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import track, { TrackingPropType } from 'react-tracking';

import validator from 'lib/validation/validator';

import * as reservationActions from 'store/reservations/actions';
import {
  isLoadingReservationsSelector,
  isReservationCompleteSelector,
  loadingMessageSelector,
} from 'store/reservations/selectors';

import Button from 'components/shared/Button';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import {
  BottomWrapper,
  ButtonWrapper,
  Checkbox,
  CheckboxLabel,
  CheckboxWrapper,
  LoadingWrapper,
  ProgressBar,
  ProgressBarPercentage,
  SubTitle,
  TextFieldInput,
  TextFieldWrapper,
  Title,
  Wrapper,
} from './style';

const mapStateToProps = (state) => ({
  isLoading: isLoadingReservationsSelector(state),
  isReservationComplete: isReservationCompleteSelector(state),
  loadingMessage: loadingMessageSelector(state),
});

const mapDispatchToProps = {
  submitReservation: reservationActions.submitReservation,
};

const isEmailInvalid = validator('email');

const UserConfirmation = ({
  isLoading,
  isReservationComplete,
  loadingMessage,
  onAdvance,
  submitReservation,
  subTitle,
  title,
  tracking: { trackEvent },
  values,
}) => {
  const [email, setEmail] = useState('');
  const [isEmailSignedUp, setIsEmailSignedUp] = useState(true);

  useEffect(() => {
    if (isReservationComplete) onAdvance();
  }, [isReservationComplete]);

  const handleChange = (value) => setEmail(value);

  const handleSubmit = (event) => {
    event.preventDefault();
    trackEvent({ event: 'Submit Button Clicked' });

    const payload = { ...values, email: { email, isEmailSignedUp } };
    submitReservation(payload);
  };

  let content;
  !isLoading
    ? (content = (
        <Wrapper>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
          <TextFieldWrapper>
            <TextFieldInput
              id={[email]}
              name={[email]}
              onChange={(event) => handleChange(event.target.value)}
              placeholder="Example: contact@sgwa.org"
              type="email"
              value={email}
            />
          </TextFieldWrapper>
          <CheckboxWrapper>
            <Checkbox
              type="checkbox"
              checked={isEmailSignedUp}
              id="isEmailSignedUp"
              name="isEmailSignedUp"
              onChange={(e) => setIsEmailSignedUp(e.target.checked)}
            />
            <CheckboxLabel htmlFor="isEmailSignedUp">
              I would like to receive email updates and news from the San Gorgonio Wilderness Association
            </CheckboxLabel>
          </CheckboxWrapper>
          <form onSubmit={handleSubmit}>
            <ButtonWrapper>
              <Button type="submit" disabled={isEmailInvalid(email)} onClick={handleSubmit}>
                Submit
              </Button>
            </ButtonWrapper>
          </form>
        </Wrapper>
      ))
    : (content = (
        <LoadingWrapper>
          <Title>{loadingMessage}</Title>
          <SubTitle>
            Please do not refresh your page, this can take up to a minute or so depending on your connection.
          </SubTitle>
          <LoadingSpinner />
        </LoadingWrapper>
      ));

  const progressBar = (
    <BottomWrapper>
      <div>
        <ProgressBarPercentage>100% completed</ProgressBarPercentage>
        <ProgressBar width={1 / 100} />
      </div>
    </BottomWrapper>
  );

  return (
    <>
      {content}
      {!isLoading && progressBar}
    </>
  );
};

UserConfirmation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isReservationComplete: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.string,
  onAdvance: PropTypes.func.isRequired,
  subTitle: PropTypes.string.isRequired,
  submitReservation: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tracking: TrackingPropType,
  values: PropTypes.objectOf(PropTypes.any).isRequired,
};

UserConfirmation.defaultProps = {
  loadingMessage: '',
  tracking: null,
};

const trackedUserConfirmation = track({
  page: 'User Confirmation',
})(UserConfirmation);

export default connect(mapStateToProps, mapDispatchToProps)(trackedUserConfirmation);
