import { createAsyncAction, createAsyncTypes } from 'lib/reduxUtils';

// Async
export const INITIALIZE_APP = createAsyncTypes('app/initializeApp');
export const initializeApp = createAsyncAction(INITIALIZE_APP);

export const LOGIN_USER = createAsyncTypes('app/loginUser');
export const loginUser = createAsyncAction(LOGIN_USER);

export const SIGNUP_USER = createAsyncTypes('app/signupUser');
export const signupUser = createAsyncAction(SIGNUP_USER);

export const FETCH_MAIN_COLLECTION = createAsyncTypes('app/fetchMainCollection');
export const fetchMainCollection = createAsyncAction(FETCH_MAIN_COLLECTION);

export const FETCH_PORTAL_COLLECTION = createAsyncTypes('app/fetchPortalCollection');
export const fetchPortalCollection = createAsyncAction(FETCH_PORTAL_COLLECTION);
