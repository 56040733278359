import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { auth } from '../../../firebase';
import { LOGIN_USER, loginUser } from '../actions';

const debug = Debug('sgwa:permit-portal:store:app:sagas:loginUser');

export function* loginUserAnonymouslySaga() {
  debug('Saga called');

  try {
    const { currentUser } = auth;

    if (!currentUser) {
      debug('No current user');
      yield call([auth, auth.signInAnonymously]);
    } else {
      debug('Current user exists', currentUser);
      yield call([currentUser, currentUser.getIdToken], true);
    }

    yield put(loginUser.success());
  } catch (e) {
    debug('Saga error', e.message);
    yield put(loginUser.error(e.message));
  }
}

export default function* () {
  yield takeLatest(LOGIN_USER.PENDING, loginUserAnonymouslySaga);
}
