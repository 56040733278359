import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as PermitTypes from 'constants/permitTypes';

import * as reservationActions from 'store/reservations/actions';
import { isLoadingReservationsSelector } from 'store/reservations/selectors';

import DayCalendar from './DayCalendar';
import OvernightCalendar from './OvernightCalendar';

const componentMapping = {
  [PermitTypes.DAY_ID]: DayCalendar,
  [PermitTypes.OVERNIGHT_ID]: OvernightCalendar,
};

const mapStateToProps = (state) => ({
  isLoading: isLoadingReservationsSelector(state),
});

const mapDispatchToProps = {
  fetchAvailability: reservationActions.fetchAvailability,
};

export const AvailabilityCalendarContainer = ({
  fetchAvailability,
  isLoading,
  moreInfoText,
  onAdvance,
  onChange,
  subTitle,
  title,
  values: { reservationType, selectedDate },
}) => {
  const CalendarComponent = componentMapping[reservationType.id];

  const handleSubmit = (userSelection) => {
    onChange('reservation', userSelection);
    onAdvance();
  };

  return (
    <CalendarComponent
      fetchAvailability={fetchAvailability}
      isLoading={isLoading}
      moreInfoText={moreInfoText}
      onSubmit={handleSubmit}
      reservationTypeId={reservationType.id}
      selectedDate={selectedDate}
      subTitle={subTitle}
      title={title}
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityCalendarContainer);
