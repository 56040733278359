import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import track, { TrackingPropType } from 'react-tracking';
import { toast } from 'react-toastify';
import history from 'lib/history';

import {
  cancelStepsSelector,
  didCancelSelector,
  errorSelector,
  isLoadingSelector,
  userReservationSelector,
} from 'store/cancel/selectors';
import * as cancelActions from 'store/cancel/actions';
import * as reservationActions from 'store/reservations/actions';

import * as StepTypes from 'constants/cancelStepTypes';
import Layout from 'components/shared/Layout';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import LinkButton from 'components/shared/LinkButton';

import CancelPage from './CancelPage';
import CancelPageConfirmation from './CancelPageConfirmation';

import { SubTitle, GoBackHomeWrapper } from './style';

const componentMapping = {
  [StepTypes.CANCEL_PAGE_ID]: CancelPage,
  [StepTypes.CANCEL_PAGE_CONFIRMATION_ID]: CancelPageConfirmation,
};

const mapStateToProps = (state) => ({
  didCancel: didCancelSelector(state),
  error: errorSelector(state),
  isLoading: isLoadingSelector(state),
  steps: cancelStepsSelector(state),
  userReservation: userReservationSelector(state),
});

const mapDispatchToProps = {
  cancelReservation: reservationActions.cancelReservation,
  fetchUserReservation: cancelActions.fetchUserReservation,
  resetCancel: cancelActions.resetCancel,
  resetError: cancelActions.resetError,
};

export const CancelPageContainer = ({
  cancelReservation,
  didCancel,
  error,
  fetchUserReservation,
  isLoading,
  resetCancel,
  resetError,
  steps,
  tracking: { trackEvent },
  userReservation,
}) => {
  const [currentStepId, setCurrentStepId] = useState(StepTypes.CANCEL_PAGE_ID);
  const [permitId, setPermitId] = useState('');

  useEffect(() => {
    if (userReservation) {
      setCurrentStepId(StepTypes.CANCEL_PAGE_CONFIRMATION_ID);
    }
  }, [userReservation]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      resetError();
    }
  }, [error]);

  const handleChange = (value) => {
    const modifiedValue = value
      .toUpperCase()
      .trim()
      .replace(/[^a-zA-Z0-9]/g, '');

    setPermitId(modifiedValue);
  };

  const handleFetchReservation = (event) => {
    event.preventDefault();
    if (permitId) fetchUserReservation(permitId);

    trackEvent({ event: 'Submit Button Clicked', permitId });
  };

  const handleCancelReservation = (event) => {
    event.preventDefault();
    const payload = {
      ...userReservation,
      permitNumber: permitId,
    };

    cancelReservation(payload);

    trackEvent({ event: 'Cancel Button Clicked' });
  };

  const handleHomeRedirect = (eventTrack) => {
    history.push('/');
    resetCancel();

    trackEvent({ event: eventTrack });
  };

  const handleResetCancelState = (eventTrack) => {
    setPermitId('');
    setCurrentStepId(StepTypes.CANCEL_PAGE_ID);
    resetCancel();

    trackEvent({ event: eventTrack });
  };

  const isLoadingMessage =
    currentStepId === StepTypes.CANCEL_PAGE_ID ? 'Loading your reservation...' : 'Cancelling your reservation...';
  const StepComponent = componentMapping[currentStepId];
  const currentStep = steps[currentStepId];
  return (
    <Layout>
      {isLoading ? (
        <>
          <SubTitle>{isLoadingMessage}</SubTitle>
          <LoadingSpinner />
        </>
      ) : (
        <>
          <StepComponent
            currentStep={currentStep}
            didCancel={didCancel}
            onCancelReservation={handleCancelReservation}
            onChange={handleChange}
            onFetchReservation={handleFetchReservation}
            onHomeRedirect={handleHomeRedirect}
            onResetCancelState={handleResetCancelState}
            permitId={permitId}
            userReservation={userReservation}
          />
          <GoBackHomeWrapper>
            <LinkButton isBiggerText onClick={() => handleHomeRedirect('Go Home Link Clicked')}>
              Go Back Home
            </LinkButton>
          </GoBackHomeWrapper>
        </>
      )}
    </Layout>
  );
};

CancelPageContainer.propTypes = {
  cancelReservation: PropTypes.func.isRequired,
  didCancel: PropTypes.bool.isRequired,
  error: PropTypes.string,
  fetchUserReservation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  resetCancel: PropTypes.func.isRequired,
  resetError: PropTypes.func.isRequired,
  steps: PropTypes.objectOf(PropTypes.any).isRequired,
  tracking: TrackingPropType,
  userReservation: PropTypes.objectOf(PropTypes.any),
};

CancelPageContainer.defaultProps = {
  error: null,
  tracking: null,
  userReservation: null,
};

const trackedCancelPageContainer = track({
  page: 'Cancel Page',
})(CancelPageContainer);

export default connect(mapStateToProps, mapDispatchToProps)(trackedCancelPageContainer);
