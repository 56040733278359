import styled from 'styled-components';
import { media } from 'styles';

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button:last-child {
    margin-top: 20px;
  }
`;

export const ButtonAdvanceWrapper = styled.div`
  margin-top: 32px;
  button:last-child {
    margin-left: 24px;
  }
`;

export const ReverseIcon = styled.img`
  width: 40px;
  height: 40px;
`;

export const ResetButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin: 12px 20px 0 0;
`;
