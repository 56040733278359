/* eslint-disable max-len */

import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import * as PermitTypes from 'constants/permitTypes';

import {
  batchCancelDayReservation,
  batchCancelOvernightReservation,
  generateCancelCountBatch,
} from './reservationUtils';

import { CANCEL_RESERVATION, cancelReservation } from '../actions';
import { db } from '../../../firebase';

const debug = Debug('sgwa:permit-portal:store:reservations:sagas:cancelReservation');

export function* cancelReservationSaga({ payload: userReservation }) {
  debug('Saga called', userReservation);

  try {
    const { locationType, reservationType } = userReservation;
    const batch = db.batch();

    if (reservationType === PermitTypes.DAY_ID) yield* batchCancelDayReservation(batch, userReservation);
    else yield* batchCancelOvernightReservation(batch, userReservation);

    generateCancelCountBatch(batch, locationType);

    yield call([batch, batch.commit]);
    debug('Batch call successfully made');

    yield put(cancelReservation.success());
  } catch (e) {
    debug('Saga error', e.message);
    yield put(cancelReservation.error(e.message));
  }
}

export default function* () {
  yield takeLatest(CANCEL_RESERVATION.PENDING, cancelReservationSaga);
}
