import React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import track from 'react-tracking';
import { toast } from 'react-toastify';

import { overnightAvailabilityTableSelector } from 'store/reservations/selectors';
import { startDateSelector } from 'store/app/selectors';

import Calendar from './Calendar';

const mapStateToProps = (state) => ({
  forcedStartDate: startDateSelector(state),
  overnightAvailability: overnightAvailabilityTableSelector(state),
});

const addingSiteIdStartDate = (userSelection, siteDates) => {
  const momentStart = Moment(userSelection.date);
  const tomorrowsDate = momentStart.clone().add(1, 'days').format('YYYY-MM-DD');

  if (siteDates) {
    let newUserSelection = { start: userSelection };
    const isTomorrowUnreservable = !!siteDates[tomorrowsDate] && siteDates[tomorrowsDate].remaining <= 0;

    if (isTomorrowUnreservable) {
      const end = {
        date: tomorrowsDate,
        id: userSelection.id,
        remaining: siteDates[tomorrowsDate].remaining,
        selectionId: `${tomorrowsDate}-${userSelection.id}`,
      };

      newUserSelection = { ...newUserSelection, end };
    }

    return newUserSelection;
  }

  return { start: userSelection };
};

const validateEndDate = (start, userSelection, siteDates) => {
  const momentStart = Moment(start.date);
  const momentEnd = Moment(userSelection.date);

  if (siteDates) {
    const isDateReservable = Object.keys(siteDates)
      .filter((dateKey) => siteDates[dateKey].remaining <= 0)
      .every((unreserverableDate) => momentEnd.isBefore(unreserverableDate) || momentStart.isAfter(unreserverableDate));

    if (isDateReservable) return { end: userSelection, start };

    toast.error("Please check your pack out date as you can't book over a date that has 0 permits left.");
    return { start };
  }

  return { end: userSelection, start };
};

const isUserSelectionsComplete = (userSelection) =>
  userSelection &&
  Object.keys(userSelection).length !== 0 &&
  Object.keys(userSelection).every((siteId) => userSelection[siteId].start && userSelection[siteId].end);

export const OvernightCalendar = ({
  fetchAvailability,
  forcedStartDate,
  isLoading,
  moreInfoText,
  onSubmit,
  overnightAvailability,
  reservationTypeId,
  selectedDate,
  subTitle,
  title,
}) => {
  const handleUserSelection = (userSelection, setUserSelection, selection, siteDates) => {
    const { id } = selection;
    let newUserSelection;

    if (userSelection && Object.keys(userSelection).includes(id)) {
      Object.keys(userSelection).forEach((siteId) => {
        if (siteId === id) {
          const { end, start } = userSelection[siteId];

          if (start.selectionId === selection.selectionId) {
            // Remove ID completely from state
            setUserSelection((prevSelection) => {
              newUserSelection = { ...prevSelection };
              delete newUserSelection[siteId];

              return newUserSelection;
            });
          } else {
            if (end && end.selectionId === selection.selectionId) newUserSelection = { start };
            else newUserSelection = validateEndDate(start, selection, siteDates);

            setUserSelection((prevSelection) => ({ ...prevSelection, [id]: newUserSelection }));
          }
        }
      });
    } else {
      newUserSelection = addingSiteIdStartDate(selection, siteDates);
      setUserSelection((prevSelections) => ({ ...prevSelections, [id]: newUserSelection }));
    }
  };

  const handleSubmit = (selection) => {
    let startDate = null;

    Object.keys(selection).forEach((siteKey) => {
      const { start } = selection[siteKey];

      const momentStart = Moment(start.date);

      if (!startDate) startDate = momentStart;
      if (startDate && momentStart.isBefore(startDate)) startDate = momentStart;
    });

    const queryDate = startDate.toDate();
    const backendId = startDate.format('MMMM-YYYY');

    onSubmit({ backendId, queryDate, selection });
  };

  const selectedStartedDate = Moment(`${forcedStartDate}`, 'MM-DD-YYYY');
  const today = Moment();
  const selectedStartDate = selectedStartedDate.isSameOrAfter(today) ? selectedStartedDate : today;

  const isSubmitButtonEnabled = (userSelection) => isUserSelectionsComplete(userSelection);
  return (
    <Calendar
      availability={overnightAvailability}
      fetchAvailability={fetchAvailability}
      isLoading={isLoading}
      isSubmitButtonEnabled={isSubmitButtonEnabled}
      moreInfoText={moreInfoText}
      onSubmit={handleSubmit}
      onUserSelection={handleUserSelection}
      reservationTypeId={reservationTypeId}
      selectedDate={selectedDate}
      subTitle={subTitle}
      title={title}
      today={selectedStartDate}
    />
  );
};

const trackedOvernightCalendar = track({
  page: 'Overnight Calendar',
})(OvernightCalendar);

export default connect(mapStateToProps, null)(trackedOvernightCalendar);
