import Debug from 'debug';
import { put, takeLatest } from 'redux-saga/effects';

import backendIds from 'constants/backendIds';
import * as PermitTypes from 'constants/permitTypes';

import { checkDayReservationAvailability, checkOvernightReservationAvailability } from './reservationUtils';

import { CHECK_RESERVATION_AVAILABILITY, checkReservationAvailability } from '../actions';

const debug = Debug('sgwa:permit-portal:store:reservations:sagas:checkReservationAvailability');

export function* checkReservationAvailabilitySaga({ payload }) {
  const {
    backendLocationId,
    reservation: { backendId, selection },
    reservationType,
  } = payload;
  debug('Saga called', payload);

  const reservationTypeId = backendIds[reservationType.id];
  try {
    if (reservationType.id === PermitTypes.DAY_ID) {
      yield* checkDayReservationAvailability(backendLocationId, reservationTypeId, backendId, selection, debug);
    } else {
      yield* checkOvernightReservationAvailability(backendLocationId, reservationTypeId, selection, debug);
    }

    debug('Reservation OK to continue');

    yield put(checkReservationAvailability.success());
  } catch (e) {
    debug('Saga error', e);
    yield put(checkReservationAvailability.error(e));
  }
}

export default function* () {
  yield takeLatest(CHECK_RESERVATION_AVAILABILITY.PENDING, checkReservationAvailabilitySaga);
}
