import fetchAvailability from './fetchAvailability';
import fetchReservationOptions from './fetchReservationOptions';
import submitReservation from './submitReservation';
import generatePermit from './generatePermit';
import cancelReservation from './cancelReservation';
import checkReservationAvailability from './checkReservationAvailability';

export default [
  cancelReservation,
  checkReservationAvailability,
  fetchAvailability,
  fetchReservationOptions,
  generatePermit,
  submitReservation,
];
