import mergeDeep from 'lib/mergeDeep';

import { FETCH_MAIN_COLLECTION } from 'store/app/actions';
import {
  CHECK_RESERVATION_AVAILABILITY,
  FETCH_AVAILABILITY,
  FETCH_RESERVATION_OPTIONS,
  GENERATE_PERMIT,
  RESET_RESERVATION,
  SUBMIT_RESERVATION,
} from './actions';

export const INITIAL_STATE = {
  availability: null,
  didFetchMainCollection: false,
  error: null,
  isLoading: false,
  isLoadingOptions: false,
  loadingMessage: null,
  locationType: null,
  options: null,
  permitPDF: null,
  queriedDates: [],
  reservation: null,
  reservationType: null,
};

export default (state = INITIAL_STATE, action) => {
  const { payload, type } = action;

  switch (type) {
    case RESET_RESERVATION:
      return {
        ...INITIAL_STATE,
      };
    case FETCH_MAIN_COLLECTION.PENDING:
      return {
        ...state,
        locationType: payload,
      };
    case FETCH_MAIN_COLLECTION.SUCCESS:
      return {
        ...state,
        didFetchMainCollection: true,
      };
    case CHECK_RESERVATION_AVAILABILITY.PENDING:
      return {
        ...state,
        loadingMessage: 'Checking your reservation...',
      };
    case CHECK_RESERVATION_AVAILABILITY.SUCCESS:
      return {
        ...state,
        loadingMessage: 'Making your reservation...',
      };
    case FETCH_AVAILABILITY.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case FETCH_AVAILABILITY.ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case FETCH_AVAILABILITY.SUCCESS: {
      if (!payload) return { ...state, isLoading: false };

      const { availabilityData, queriedDateId, reservationTypeId } = payload;

      let { queriedDates } = state;
      if (queriedDates)
        queriedDates = queriedDates.includes(queriedDateId) ? queriedDates : [...queriedDates, queriedDateId];

      let dates = availabilityData;
      if (state.availability) dates = mergeDeep({}, state.availability, availabilityData);

      return {
        ...state,
        availability: dates,
        isLoading: false,
        queriedDates,
        reservationType: reservationTypeId,
      };
    }
    case FETCH_RESERVATION_OPTIONS.SUCCESS:
      return {
        ...state,
        options: { ...payload },
      };
    case FETCH_RESERVATION_OPTIONS.ERROR:
      return {
        ...state,
        error: payload,
      };
    case SUBMIT_RESERVATION.PENDING:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SUBMIT_RESERVATION.ERROR:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case SUBMIT_RESERVATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        reservation: payload,
      };
    case GENERATE_PERMIT.PENDING:
      return {
        ...state,
        loadingMessage: 'Making your permit...',
      };
    case GENERATE_PERMIT.SUCCESS:
      return {
        ...state,
        loadingMessage: 'Almost done...',
        permitPDF: payload,
      };
    default:
      return state;
  }
};
