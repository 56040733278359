import Debug from 'debug';
import { call, put, takeLatest } from 'redux-saga/effects';

import { db } from '../../../firebase';
import { FETCH_PORTAL_COLLECTION, fetchPortalCollection } from '../actions';

const debug = Debug('sgwa:permit-portal:store:app:sagas:fetchPortalCollection');

export function* fetchPortalCollectionSaga() {
  debug('Saga called');

  try {
    const ref = db.collection('portal');
    const data = yield call([ref, ref.get]);

    const snapshotData = data.docs.reduce((acc, doc) => {
      const documentData = doc.data();
      acc[doc.id] = { ...documentData };

      return acc;
    }, {});

    debug('Portal collection fetched', snapshotData);

    yield put(fetchPortalCollection.success(snapshotData.data));
  } catch (e) {
    debug('Saga error', e);
    yield put(fetchPortalCollection.error(e));
  }
}

export default function* () {
  yield takeLatest(FETCH_PORTAL_COLLECTION.PENDING, fetchPortalCollectionSaga);
}
