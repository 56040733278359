import React from 'react';

import styled from 'styled-components';
import { media } from 'styles';

export const variantStyles = {
  default: {
    color: '#2e5941',
  },
  red: {
    color: '#D04949',
  },
};

export const StyledButton = styled.button`
  background: ${(p) => variantStyles[p.variant].color};
  color: #ffffff;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  font-size: 1.4rem;
  outline: 0;
  ${media.twoDimMin.tablet`font-size: 1.6rem;`}

  &.focus-visible {
    box-shadow: ${(p) => variantStyles[p.variant].color} 0px 0px 0px 2px;
    border: 1px solid ${(p) => variantStyles[p.variant].color};
  }

  ${(p) =>
    !p.disabled &&
    `
    &:focus,
    &:hover {
      background: ${variantStyles[p.variant].color};
      box-shadow: ${variantStyles[p.variant].color} 0px 0px 0px 2px;
      border: 1px solid ${variantStyles[p.variant].color};
    }
  `}

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }

  ${(p) =>
    p.type === 'Download'
      ? `
      &:after {
        content: '';
        display: inline-block;
        height: 0;
        width: 0;
        margin-left: 14px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 8px solid #ffffff;
    
        ${media.twoDimMin.tablet`
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 10px solid #FFFFFF;
        `}
      }
    `
      : `
    &:after {
      content: '';
      display: inline-block;
      transform: rotate(45deg);
      margin-left: 14px;
      height: 14px;
      width: 8px;
      border-bottom: 3px solid #ffffff;
      border-right: 3px solid #ffffff;

      ${media.twoDimMin.tablet`
        height: 18px;
        width: 10px;
        border-bottom: 4px solid #FFFFFF;
        border-right: 4px solid #FFFFFF;
      `}
    }
  `}
`;

const Button = ({ children, variant = 'default', disabled = false, onClick, type = 'button' }) => (
  <StyledButton variant={variant} type={type} disabled={disabled} onClick={onClick}>
    {children}
  </StyledButton>
);

export default Button;
