import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/shared/Button';
import { CancelPageButtonWrapper, SubTitle, TextFieldInput, TextFieldWrapper, Title, Wrapper } from './style';

export const CancelPage = ({
  currentStep: { buttonText, placeholder, subTitle, title },
  onChange,
  onFetchReservation,
  permitId,
}) => (
  <Wrapper>
    <Title>{title}</Title>
    <SubTitle>{subTitle}</SubTitle>
    <form>
      <TextFieldWrapper>
        <TextFieldInput
          id="permitId"
          name="permitId"
          onChange={(event) => onChange(event.target.value)}
          placeholder={placeholder}
          required
          type="text"
          value={permitId}
        />
        {!!permitId && (
          <CancelPageButtonWrapper>
            <Button type="submit" onClick={onFetchReservation}>
              {buttonText}
            </Button>
          </CancelPageButtonWrapper>
        )}
      </TextFieldWrapper>
    </form>
  </Wrapper>
);

CancelPage.propTypes = {
  currentStep: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  onFetchReservation: PropTypes.func.isRequired,
  permitId: PropTypes.string,
};

CancelPage.defaultProps = {
  permitId: '',
};

export default CancelPage;
